@use 'sass:math';

$primary: #ff0082;
$primary-hover: darken($primary, 10%);

$secondary: #32c84b;
$secondary-hover: darken($secondary, 10%);

$final: #caaa68;

$placeholder-grey: #e2e2e2;

$text-colour: #242424;

$black: #000;
$white: #fff;

$very-light-ui: #f1f1f1;
$light-ui: #e2e2e2;
$medium-ui: #979797;
$darker-ui: #3e3e3e;
$dark-ui: #242424;
$darkest-ui: #0e0e0e;

$light-placeholder-gradient: linear-gradient(to top right, $very-light-ui, $light-ui);
$dark-placeholder-gradient: linear-gradient(to top right, $darker-ui, $dark-ui);

/* Social Colours */
$facebook: #117df2;
$twitter: #55acee;
$youtube: #b00;
$instagram: #e95950;
$instagram-gradient: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
$whatsapp: #43d854;

/* Breakpoints */
$break-small-mobile: 375px;
$break-mobile: 415px;
$break-phablet: 640px;
$break-tablet: 840px;
$break-desktop: 1025px;
$break-wide: 1300px;

/* Aspect ratios */
$aspect-16-10: math.div(10, 16) * 100%; // 16:10
$aspect-16-9: math.div(9, 16) * 100%; // 16:9
$aspect-2-3: math.div(3, 2) * 100%; // 2:3
$aspect-3-2: math.div(2, 3) * 100%; // 2:3
$aspect-3-4: math.div(4, 3) * 100%; // 3:4
$aspect-4-3: math.div(3, 4) * 100%; // 4:3

/* Layout */
$wrapper-width: 136rem;
$spacing-xs: .4rem;
$spacing-s: .8rem;
$spacing: 1.6rem;
$spacing-l: 3.2rem;
$spacing-xl: 6.4rem;

$navigation-width: 9.1rem;
$navigation-width-desktop: 8.8rem;
$navigation-height-tablet: 6.6rem;

$scorebox-height: 15.3rem;

$article-padding: #{$spacing * 5};
$article-padding-desktop: $spacing-xl;
$article-padding-phablet: $spacing;

/* Z-Index Layers */
$level-one: 10;
$level-two: 20;
$level-three: 30;
$level-four: 40;
$level-five: 50;
$level-six: 60;

$transition: .2s ease;
$image-hover-transition-in: transform 6s cubic-bezier(.19, 1, .22, 1);
$image-hover-transition-out: transform 3s cubic-bezier(.19, 1, .22, 1);

// Video Player
$control-bar-height: 5.7rem;
