.scorecard {
    $this: &;
    padding-top: $spacing-l;
    overflow-x: hidden;

    &__status-text {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: $spacing;
        margin-bottom: $spacing;
        color: $white;
    }

    &__header {
        min-height: 2.8rem;
        display: flex;
        align-items: center;
        margin-bottom: $spacing;
    }

    &__team-badge {
        width: 4.8rem;
        height: 4.8rem;
        margin-right: $spacing;
    }

    &__header-title {
        display: flex;
        flex-direction: column;
    }

    &__header-label {
        color: $primary;
        font-weight: $bold;
        font-size: 1.2rem;
        line-height: 1;
        text-transform: uppercase;
    }

    &__header-team {
        color: $white;
        font-family: var(--team-theme-title-font);
        font-size: 2.8rem;
        line-height: .9;
        text-transform: uppercase;
    }

    &__header-swipe {
        width: 2.8rem;
        height: 2.8rem;
        align-self: flex-end;
        margin-left: auto;
        fill: $medium-ui;
    }
    
    &__table-container {
        margin-bottom: $spacing-xl;
        scroll-behavior: smooth;
        overflow-x: auto;

        &--batting {
            margin-bottom: $spacing;
        }
    }

    &__table {
        width: 100%;
        table-layout: fixed;
        color: $white;
        white-space: nowrap;
    }

    &__table-head-row {
        background-color: $darker-ui;
    }

    &__table-heading {
        width: 12rem;
        line-height: 1;
        font-size: 1.4rem;
        padding: .8rem 0;
        text-transform: uppercase;
        text-align: center;
        color: $light-ui;

        &--player {
            width: 50rem;
            padding-left: 1.2rem;
            text-align: left;
        }

        &--lower {
            text-transform: lowercase;
        }
    }

    &__table-row {
        border-top: 1px solid $darker-ui;

        &--extras {
            background-color: $dark-ui;

            #{$this}__table-cell-alt {
                display: flex;
                align-items: center;
                font-size: 1.4rem;
                font-weight: $bold;
                padding: 1.4rem 0 1.4rem 20rem;
                text-align: left;
                text-transform: uppercase;

                &--extra {
                    color: rgba($white, .6);
                    font-size: 1.2rem;
                    font-weight: $regular;
                    text-transform: capitalize;
                    padding: 0 $spacing-s;
                }
            }
        }

        &--total {
            border-top-color: $primary;

            #{$this}__table-cell-alt {
                font-size: 1.8rem;
                font-weight: $bold;
                line-height: 1.33;
                padding: 1.8rem 0 2.2rem 20rem;
                text-align: left;
                text-transform: uppercase;
            }
        }
    }

    &__table-cell {
        text-align: center;

        &--player {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 2.4rem;
            text-align: left;
        }

        &--border {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: $spacing;
                bottom: $spacing;
                width: .1rem;
                background-color: $dark-ui;
            }
        }
    }

    &__player-image-container {
        width: 7.2rem;
        height: 6.4rem;
        position: relative;
        margin-right: $spacing;
        flex-shrink: 0;

        &--out {

            &::before { // 'out text'
                content: 'W';
                @include title-font;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 4rem;
                font-weight: $bold;
                z-index: $level-one;
            }

            &::after { // pink strikethrough line
                content: '';
                width: 60%;
                height: .8rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-52%, -36%) rotate(6deg);
                background-color: $primary;
                z-index: $level-one;
            }

            #{$this}__player-img {
                filter: grayscale(1);
                opacity: .5;
            }
        }
    }

    &__player-img {
        object-position: top;

        &--silhouette {
            width: 90%;
            height: 90%;
            position: absolute;
            bottom: 0;
            left: 0;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__player-meta {
        display: flex;
        flex-direction: column;
    }

    &__player-name-container {
        display: flex;
    }

    &__player-name {
        font-size: 1.8rem;
        font-weight: $bold;
        line-height: 1;
        padding-left: $spacing;
        text-transform: uppercase;
        white-space: normal;

        &--batting,
        &--out {
            padding-left: $spacing-s;
        }
    }

    &__player-dismissal-message {
        font-size: 1.3rem;
        color: $medium-ui;
        line-height: 1;
        margin-top: .4rem;
        font-weight: $regular;
    }

    &__icon-batting {
        width: 1.4rem;
        height: 1.4rem;
        fill: $secondary;
    }

    &__icon-out {
        width: 1.4rem;
        height: 1.4rem;
        fill: $primary;
    }

    // Table Toggle

    &__toggle-container {
        width: calc(100% + #{$spacing});
        display: flex;
        justify-content: center;
        align-items: center;
        background: radial-gradient(ellipse at bottom, $dark-ui, $black);
        margin: 0 -#{$spacing-s} $spacing;
    }
    
    &__toggle {
        @include button-reset;
        width: 50%;
        padding: $spacing;
        color: $medium-ui;
        border-bottom: 2px solid $darker-ui;
        text-transform: uppercase;
        text-align: center;
        font-size: 1.2rem;
        font-weight: $bold;

        &.active {
            color: $white;
            border-bottom-color: $primary;
        }
    }

    @media (max-width: $break-wide) {

        &__table-row {
    
            &--extras,
            &--total {
    
                #{$this}__table-cell-alt {
                    padding-left: 14rem;

                    &--extra {
                        padding-left: $spacing-s;
                    }
                }
            }
        }
    }

    @media (max-width: $break-tablet) {
        padding-top: $spacing;
        
        &__tabs {

            .tabs__tab-list {
                margin-top: $spacing;
            }
        }

        &__header {
            margin-bottom: $spacing-s;
        }

        &__team-badge {
            width: 4rem;
            height: 4rem;
            margin-right: $spacing-s;
        }

        &__table-container {
            margin-bottom: $spacing-l;

            &--batting {
                margin-bottom: $spacing;
            }
        }

        &__table-heading {
            width: 5.6rem;
            padding: $spacing-s 0;
            font-size: 1rem;

            &--player {
                width: 24rem;
                padding-left: $spacing-s;
            }

            &--sr {
                width: 7.8rem;
            }

            &.hide {
                display: none;
            }
        }

        &__main {

            &:last-of-type {

                #{$this}__table-wrapper {
                    margin-bottom: 0;
                }
            }
        }

        &__table-wrapper {
            margin-bottom: $spacing-l;
        }

        &__table-row {

            &--extras,
            &--total {

                #{$this}__table-cell-alt {
                    flex-direction: column;
                    align-items: flex-start;
                    font-size: 1.2rem;
                    padding: $spacing-s;
                    text-align: left;
                    
                    &--extra {
                        font-size: 1rem;
                        line-height: .8;
                        padding: .6rem 0 0;
                    }
                }
            }
            
            &--extras {
                
                #{$this}__table-cell-alt {
                    text-transform: capitalize;
                }
            }

            &--total {

                #{$this}__table-cell-alt {
                    padding: $spacing 0 $spacing $spacing-s;
                }
            }

            &--bowler {

                #{$this}__table-cell--border::after {
                    content: none;
                }
            }
        }

        &__table-cell {
            font-size: 1.6rem;

            &--player {
                padding-left: 0;
            }

            &.hide {
                display: none;
            }
        }

        &__player-image-container {
            width: 5.2rem;
            height: 5.2rem;
            margin-right: $spacing-s;

            &--out {

                &::before {
                    font-size: 3.2rem;
                }

                &::after {
                    width: 4rem;
                    height: .4rem;
                }
            }
        }

        &__player-name {
            font-size: 1.4rem;
        }

        &__icon-batting {
            width: 1.1rem;
            height: 1.1rem;
        }

        &__icon-out {
            width: 1rem;
            height: 1rem;
            margin-left: 0;
        }

        &__player-dismissal-message {
            font-size: 1.1rem;
        }
    }

    @media (max-width: $break-mobile) {

        &__table-cell {
            font-size: 1.4rem;
        }
    }
}
