body {
    // ----- Colours
    --team-theme-primary: #{$primary};
    --team-theme-secondary: #{$secondary};
    --team-theme-tertiary: #{$white};
    --team-theme-text: #{$black};
    --team-theme-captain-background: #{$black};
    --team-theme-captain-text: #{$white};
    --team-theme-bg-pattern-size: cover;

    // ----- Typography
    --team-theme-title-font: hundred-ball, Impact, Helvetica Neue, sans-serif;
    --team-theme-title-case: uppercase;
    --team-theme-title-line-height: .9;
    --team-theme-title-letter-spacing: 0;
    --team-theme-secondary-font: styrenea, Helvetica Neue, sans-serif;
    --team-theme-card-line-height: normal;
}

.t-birmingham-phoenix {
    --team-theme-primary: #{$bp-primary};
    --team-theme-secondary: #{$bp-secondary};
    --team-theme-tertiary: #{$bp-tertiary};
    --team-theme-text: #{$bp-text};
    --team-theme-captain-background: #{$bp-tertiary};
    --team-theme-captain-text: #{$bp-secondary};
    --team-theme-bg-pattern-size: 300%;
    --team-theme-title-font: ff-ginger-pro, styrenea, Helvetica Neue, sans-serif;
    --team-theme-title-line-height: .8;
}

.t-london-spirit {
    --team-theme-primary: #{$ls-primary};
    --team-theme-secondary: #{$ls-secondary};
    --team-theme-tertiary: #{$ls-tertiary};
    --team-theme-text: #{$ls-text};
    --team-theme-captain-background: #{$ls-primary};
    --team-theme-captain-text: #{$ls-text};
    --team-theme-bg-pattern-size: 100%;
    --team-theme-title-font: druk-cond-super, Impact, sans-serif;
    --team-theme-secondary-font: gza-semibold, styrenea, Helvetica Neue, sans-serif;
    -webkit-font-smoothing: antialiased;
    --team-theme-title-letter-spacing: 1.2px;
}

.t-manchester-originals {
    --team-theme-primary: #{$mo-primary};
    --team-theme-secondary: #{$mo-secondary};
    --team-theme-tertiary: #{$mo-tertiary};
    --team-theme-text: #{$mo-text};
    --team-theme-captain-background: #{$mo-primary};
    --team-theme-captain-text: #{$mo-secondary};
    --team-theme-bg-pattern-size: 120%;
    --team-theme-title-font: originals-headline, hundred-ball, styrenea, Helvetica Neue, sans-serif;
    --team-theme-title-case: none;
    --team-theme-title-line-height: .9;
}

.t-northern-superchargers {
    --team-theme-primary: #{$ns-primary};
    --team-theme-secondary: #{$ns-secondary};
    --team-theme-tertiary: #{$ns-tertiary};
    --team-theme-text: #{$ns-text};
    --team-theme-captain-background: #{$ns-tertiary};
    --team-theme-captain-text: #{$ns-text};
    --team-theme-bg-pattern-size: cover;
    --team-theme-title-font: sharp-grotesk-black-italic, hundred-ball, styrenea, Helvetica Neue, sans-serif;
    -webkit-font-smoothing: antialiased;
    --team-theme-title-letter-spacing: 1px;
    --team-theme-title-case: uppercase;
    --team-theme-title-line-height: .9;
}

.t-oval-invincibles {
    --team-theme-primary: #{$oi-primary};
    --team-theme-secondary: #{$oi-secondary};
    --team-theme-tertiary: #{$oi-tertiary};
    --team-theme-text: #{$oi-text};
    --team-theme-captain-background: #{$oi-primary};
    --team-theme-captain-text: #{$black};
    --team-theme-title-font: lucifer-sans-bold, hundred-ball, Helvetica Neue, sans-serif;
    --team-theme-title-case: uppercase;
    --team-theme-title-line-height: 1;
    --team-theme-secondary-font: styrenea, Helvetica Neue, sans-serif;
    --team-theme-card-line-height: 1.1;
}

.t-southern-brave {
    --team-theme-primary: #{$sb-primary};
    --team-theme-secondary: #{$sb-secondary};
    --team-theme-tertiary: #{$sb-tertiary};
    --team-theme-text: #{$sb-text};
    --team-theme-captain-background: #{$sb-tertiary};
    --team-theme-captain-text: #{$sb-text};
    --team-theme-bg-pattern-size: cover;
    --team-theme-title-font: px-grotesk-bold, hundred-ball, Helvetica Neue, sans-serif;
    --team-theme-title-case: none;
    --team-theme-title-line-height: .9;
    --team-theme-card-line-height: 1.1;
}

.t-trent-rockets {
    --team-theme-primary: #{$tr-primary};
    --team-theme-secondary: #{$tr-secondary};
    --team-theme-tertiary: #{$tr-tertiary};
    --team-theme-text: #{$tr-text};
    --team-theme-captain-background: #{$tr-secondary};
    --team-theme-captain-text: #{$tr-tertiary};
    --team-theme-bg-pattern-size: cover;
    --team-theme-title-font: euclid-flex-bold, hundred-ball, Helvetica Neue, sans-serif;
    --team-theme-title-line-height: .9;
}

.t-welsh-fire {
    --team-theme-primary: #{$wf-primary};
    --team-theme-secondary: #{$wf-secondary};
    --team-theme-tertiary: #{$wf-tertiary};
    --team-theme-text: #{$wf-text};
    --team-theme-captain-background: #{$wf-primary};
    --team-theme-captain-text: #{$wf-text};
    --team-theme-bg-pattern-size: 105%;
    --team-theme-title-font: code-pro-bold, hundred-ball, Helvetica Neue, sans-serif;
    --team-theme-title-line-height: 1;
    --team-theme-secondary-font: code-pro-regular, styrena, Helvetica Neue, sans-serif;
    --team-theme-card-line-height: 1.2;
}

.t-team-theme {

    .team-background {
        background-color: var(--team-theme-primary);
        background-size: var(--team-theme-bg-pattern-size);
    }

    .team-title-font {
        font-family: var(--team-theme-title-font);
        line-height: var(--team-theme-title-line-height);
    }

    .team-copy-font {
        font-family: var(--team-theme-secondary-font);
    }

    .button {
        background-color: var(--team-theme-primary);
        color: var(--team-theme-text);

        @include hover {
            background-color: var(--team-theme-secondary);
        }

        &--secondary {
            background-color: var(--team-theme-secondary);

            @include hover {
                background-color: var(--team-theme-primary);
            }
        }

        &--unthemed {
            background-color: var(--team-theme-tertiary);
            color: var(--team-theme-text);

            @include hover {
                background-color: var(--team-theme-secondary);
            }
        }

        @include hover {
            background-color: var(--team-theme-secondary);
        }
    }

    &.t-birmingham-phoenix {

        .team-background {
            background-image: url(../../i/team-backgrounds/birmingham-phoenix2.svg);

            &--alt {
                background-image: url(../../i/team-backgrounds/birmingham-phoenix.svg);
                background-size: cover;
                background-position: 0 0;
            }
        }

        .team-player-background {
            background-image: url(../../i/team-player-backgrounds/birmingham-phoenix.jpg);
        }

        .button--unthemed {
            color: $white
        }

        .player-card__forename,
        .player-card__surname {
            line-height: .9;
        }

        .player-card__surname {
            color: var(--team-theme-secondary);
        }

        .player-card__role {
            background-color: var(--team-theme-secondary);
            margin-top: .6rem;
        }
    }

    &.t-london-spirit {

        .team-background {
            background-image: url(../../i/team-backgrounds/london-spirit.svg);

            &--alt {
                background-image: url(../../i/team-backgrounds/london-spirit2.svg);
            }
        }

        .team-player-background {
            background-image: url(../../i/team-player-backgrounds/london-spirit.jpg);
        }

        .player-card__captain {
            font-family: var(--team-theme-secondary-font);
            font-size: 1.4rem;
        }

        .player-card__forename {
            font-size: 4rem;
        }

        .player-card__surname {
            color: $white;
            font-size: 6.4rem;
        }

        .player-card__role {
            background-color: $white;
            color: var(--team-theme-primary);
            font-family: var(--team-theme-secondary-font);
            text-transform: capitalize;
        }

        @media (max-width: $break-tablet) {

            .player-card__forename {
                font-size: 2.5rem;
            }

            .player-card__surname {
                font-size: 4.2rem;
            }
        }
    }

    &.t-manchester-originals {

        .team-background {
            background-image: url(../../i/team-backgrounds/manchester-originals.svg);

            &--alt {
                background-image: url(../../i/team-backgrounds/manchester-originals2.svg);
                background-color: var(--team-theme-tertiary);
            }
        }

        .team-player-background {
            background-image: url(../../i/team-player-backgrounds/manchester-originals.jpg);
        }

        .button {
            background-color: var(--team-theme-secondary);
            color: var(--team-theme-primary);

            @include hover {
                background-color: var(--team-theme-tertiary);
                color: var(--team-theme-secondary);
            }
        }

        &.tabs__selector {

            @include hover {
                background-color: var(--team-theme-tertiary);
                color: var(--team-theme-secondary);
            }
        }

        .tag {
            background-color: var(--team-theme-tertiary);
        }

        .player-card__forename,
        .player-card__surname {
            background-color: var(--team-theme-secondary);
            color: var(--team-theme-primary);
        }

        .player-card__forename {
            font-size: 2.4rem;
        }

        .player-card__surname {
            font-size: 3.2rem;
        }

        .player-card__role {
            color: $white;
        }
    }

    &.t-northern-superchargers {

        .team-background {
            background-image: url(../../i/team-backgrounds/northern-superchargers3.svg);

            &--alt {
                background-image: url(../../i/team-backgrounds/northern-superchargers1.svg);
            }
        }

        .team-player-background {
            background-image: url(../../i/team-player-backgrounds/northern-superchargers.jpg);
        }

        &.tabs__selector {

            @include hover {
                background-color: var(--team-theme-secondary);
            }

            &.active {
                background-color: var(--team-theme-secondary);
            }
        }

        .player-card__forename {
            background-color: $white;
            color: var(--team-theme-primary);
            font-size: 4.2rem;
        }

        .player-card__surname {
            background-color: var(--team-theme-tertiary);
            color: $white;
            font-size: 5.8rem;
        }

        .player-card__role {
            background-color: $white;
            color: var(--team-theme-primary);
            font-family: var(--team-theme-title-font);
            font-size: 2.8rem;
        }

        .player-card__captain {
            font-size: 2.2rem;
            line-height: 1.1;
        }

        @media (max-width: $break-tablet) {

            .player-card__forename {
                font-size: 3rem;
            }

            .player-card__surname {
                font-size: 4.6rem;
            }

            .player-card__role,
            .player-card__captain {
                font-size: 1.8rem;
            }
        }
    }

    &.t-oval-invincibles {

        .team-background {
            background-image: url(../../i/team-backgrounds/oval-invincibles1.svg);

            &--alt {
                background-image: url(../../i/team-backgrounds/oval-invincibles2.svg);
                background-position: top -5rem right -5rem;
            }
        }

        .team-player-background {
            background-image: url(../../i/team-player-backgrounds/oval-invincibles.jpg);
        }

        .button {
            color: $black;

            @include hover {
                background-color: var(--team-theme-tertiary);
                color: var(--team-theme-secondary);
            }
        }

        .player-card__forename,
        .player-card__surname,
        .player-card__role {
            padding-top: .2rem;
        }

        .player-card__forename {
            color: var(--team-theme-primary);
            font-size: 2rem;
        }

        .player-card__surname {
            background-color: var(--team-theme-primary);
            color: $black;
            font-size: 3.2rem;
        }

        .player-card__role {
            background-color: $black;
            color: var(--team-theme-primary);
            line-height: 1.3;
            font-size: 1.6rem;
            margin-top: -.2rem;
        }

        @media (max-width: $break-phablet) {

            .player-card__surname {
                font-size: 2.6rem;
            }
        }
    }

    &.t-southern-brave {

        .team-background {
            background-image: url(../../i/team-backgrounds/southern-brave.svg);

            &--alt {
                background-image: url(../../i/team-backgrounds/southern-brave2.svg);
                background-position: 25rem 0;
                background-repeat: no-repeat;
            }
        }

        .team-player-background {
            background-image: url(../../i/team-player-backgrounds/southern-brave.jpg);
        }

        .button {
            background-color: var(--team-theme-secondary);
            color: $black;

            @include hover {
                background-color: var(--team-theme-primary);
            }
        }

        .player-card__forename {
            color: var(--team-theme-primary);
        }

        .player-card__role,
        .player-card__captain {
            text-transform: capitalize;
        }

        .player-card__role {
            margin-top: .6rem;
        }

        @media (max-width: $break-tablet) {

            .team-background--alt {
                background-position: 8rem 0;
            }
        }
    }

    &.t-trent-rockets {

        .team-background {
            background-image: url(../../i/team-backgrounds/trent-rockets1.svg);

            &--alt {
                background-image: url(../../i/team-backgrounds/trent-rockets2.svg);
            }
        }

        .team-player-background {
            background-image: url(../../i/team-player-backgrounds/trent-rockets.jpg);
        }

        .player-card__role {
            background-color: var(--team-theme-secondary);
            color: $white;
        }

        .player-card__forename {
            font-size: 2.4rem;
        }

        .player-card__surname {
            font-size: 3.2rem;
        }
    }

    &.t-welsh-fire {

        .team-background {
            background-image: url(../../i/team-backgrounds/welsh-fire.svg);

            &--alt {
                background-image: url(../../i/team-backgrounds/welsh-fire2.svg);
            }
        }

        .team-player-background {
            background-image: url(../../i/team-player-backgrounds/welsh-fire.jpg);
        }

        .player-card__caption {
            line-height: .9;
        }

        .player-card__forename {
            font-size: 2.4rem;
            padding-bottom: .4rem;
        }

        .player-card__surname {
            padding-bottom: .8rem;
            font-size: 3.2rem;
            color: $white;
        }

        .player-card__role {
            padding: 0 .6rem .4rem;
            color: $white;
        }
    }
}
