.article-image { // markup dictated by embeddable widgets set in the CMS
    margin-left: -$article-padding;

    &__container {
        height: 0;
        position: relative;
        display: block;
        padding-top: $aspect-16-9;

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            object-position: center;
        }
    }

    &__caption {
        position: relative;
        display: block;
        margin-left: $article-padding;
        padding: .6rem 3rem .6rem $spacing;
        background-color: $primary;
        line-height: 1;
        color: $white;
        word-break: break-word;

        p { // for some reason the photo caption is wrapped in a p tag, so we have to remove its margin
            // stylelint-disable-next-line declaration-no-important
            font-size: 1.4rem;
            // stylelint-disable declaration-no-important
            line-height: 1.6 !important;
            margin-bottom: 0 !important;
            // stylelint-enable
        }
    }

    @media (max-width: $break-desktop) {
        margin-left: -$article-padding-desktop;

        &__image-caption p {
            font-size: 1.2rem;
        }
    }

    @media (max-width: $break-phablet) {
        margin: 0 -$article-padding-phablet;

        &__caption {
            margin: 0 $spacing 0 0;
            padding-left: $article-padding-phablet;
        }
    }
}
