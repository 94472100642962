.in-play-player {
    color: $white;
    transition: transform .3s ease, opacity .2s .1s ease;

    &--inactive {
        transform: scale(.92);
        opacity: .7;
    }

    &__card {
        margin-right: $spacing-s;
        margin-bottom: $spacing-s;
        
        .player-card__image-container {
            border: 3px solid $white;
        }
    
        .player-card__caption {
            display: none;
        }
    }
    
    &__caption {
        @include title-font;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        line-height: .9;
    }
    
    &__name {
        @include truncate;
        white-space: normal;
        margin-bottom: .4rem;
        font-size: 2.4rem;
        color: $white;
    }
    
    &__stats {
        font-size: 1.8rem;
        color: $primary;
    }
    
    &__stat--secondary {
        opacity: .8;
    }

    @media (max-width: $break-phablet) {

        &--inactive {
            transform: none;
        }

        .player-card { // this is the only location in which the player cards swap aspect ratio so this styling can be specific

            &__image-container {
                padding-top: $aspect-16-9;
                border-width: 2px;
                border-radius: 4px;
                background-size: 100%;
                background-position: 0 15%;
            }

            &__image--silhouette svg {
                width: 100%;
                height: auto;
            }

            &__img {
                object-position: top;
            }

            &__meta {
                padding: .2rem;
            }

            &__meta-icon {
                width: 1.2rem;
                height: 1.2rem;
            }

            &.t-london-spirit {

                .player-card__image-container {
                    background-position: 0 50%;
                }
            }
        }
        
        &__name {
            @include copy-font;
            margin-bottom: .2rem;
            font-size: 1.4rem;
            line-height: 1;
            font-weight: $bold;
        }
    
        &__stats {
            font-size: 1.4rem;
        }
    
        &__stat {
            display: block;
        }
    }

    @media (max-width: $break-mobile) {

        .player-card { // this is the only location in which the player cards swap aspect ratio so this styling can be specific

            &__image-container {
                height: 0;
            }

            &__image {
                width: 100%;

                &--silhouette svg {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
