.generic-card {
    $this: &;
    user-select: none;
    overflow: hidden;

    .pre-match {
        overflow: visible;
    }

    @media (min-width: $break-tablet) {
        
        &__chevrons {
            fill: $secondary;
            position: absolute;
        
            &--top {
                top: -50%;
                right: -50%;
            }
        
            &--bottom {
                bottom: -50%;
                left: -50%;
            }
        }

        &__loaded {
            height: 100%;
        }
        
        &__content {
            height: 100%;
            position: relative;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding: $spacing-l 8rem $spacing-l 4rem;
            z-index: $level-one;
            overflow-y: auto;
            overflow-x: hidden;
        }

        &__info-container {
            width: 100%;
        }
    }

    @media (max-width: $break-tablet) {

        &__chevrons--mobile {
            position: absolute;
            right: -20%;
            bottom: -10%;
            fill: $secondary;
        }

        &__loaded {
            background-color: $black;
            transition: opacity .2s ease;

            &-appear {
                opacity: 0;
            }
        
            &-appear-done {
                opacity: 1;
            }
        }

        &__content {
            position: relative;
            padding: $spacing;
            z-index: $level-one;
        }
    }
}