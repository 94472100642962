.button {
    $this: &;
    @include link-reset;
    position: relative;
    display: block;
    padding: 1.6rem 3.2rem;
    background-color: $black;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.4rem;
    color: $white;
    line-height: 1;
    transition: background-color $transition, transform $transition, box-shadow $transition;
    // in case the button is not an anchor element (it should be), cursor is set
    cursor: pointer;

    @include hover {
        background-color: $dark-ui;
        transform: translateY(-2px);
        box-shadow: -2px 2px 3px 1px rgba(0, 0, 0, .15);
    }

    &--primary {
        background-color: $primary;

        @include hover {
            background-color: $primary-hover;
        }
    }

    &--secondary {
        background-color: $secondary;

        @include hover {
            background-color: $secondary-hover;
        }
    }

    @media (max-width: $break-tablet) {
        padding: 1.2rem 1.6rem;
    }
}
