.squads-sponsor {
    margin-left: auto;
    color: $white;

    &__image-container {
        width: 12rem;
        height: 0;
        position: relative;
        padding-top: $aspect-16-9;
    }

    &__text {
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        margin-right: .4rem;
        text-transform: uppercase;
        font-size: 1.2rem;
        word-spacing: 100vw;
        text-align: right;
    }

    @media (max-width: $break-phablet) {

        &__image-container {
            width: 8rem;
        }
    }
}