.arrows-live {
    width: 100%;

    &__arrow {
        fill: $black;
        animation: fade-pulse 2.2s ease infinite;

        &--1 {
            animation-delay: 0s;
        }

        &--2 {
            animation-delay: .3s;
        }

        &--3 {
            animation-delay: .6s;
        }
    }
}
