@mixin wrapper {
    width: 100%;
    max-width: $wrapper-width;
    margin: 0 auto;
    padding: 0 $spacing;

    @media (max-width: $break-tablet) {
        padding: 0 $spacing-s;
    }
}

.wrapper {
    @include wrapper;
}

.mobile-navigation-offset { // provides an offset for the mobile navigation so it doesn't overlap page content

    @media (max-width: $break-tablet) {
        padding-bottom: calc(#{$navigation-height-tablet} + #{$spacing-l}); // navigation height plus the extra height added by the ball-by-ball icon
    }
}
