.promo-generic {
    $this: &;
    color: $black;

    &__wrapper {
        position: relative;
        display: block;
        padding: $spacing;
        background-color: $secondary;
        overflow: hidden;
    }

    &__title {
        position: relative;
        margin-bottom: $spacing;
        font-size: 5.2rem;
        line-height: .9;
        z-index: $level-one;

        .highlight {
            padding: .2rem .4rem;
            background-color: $primary;
            color: $white;
            display: block;
            width: fit-content;
            transition: transform $transition;
        }
    }

    &__description {
        width: 75%;
        position: relative;
        margin-bottom: $spacing;
        font-size: 1.8rem;
        font-weight: $bold;
        z-index: $level-one;
    }

    &__link-container {
        position: relative;
        display: flex;
        z-index: $level-one;
    }

    &__external-icon {
        width: 1.2rem;
        height: 1.2rem;
        fill: currentColor;
        margin-left: $spacing-s;
    }

    &__chevron {
        position: absolute;
        bottom: 0;
        right: 0;
        fill: $primary;
    }

    &--alternate {
        color: $white;

        #{$this}__wrapper {
            background-color: $primary;
        }

        #{$this}__title {

            .highlight {
                background-color: $secondary;
            }
        }

        #{$this}__chevron {
            fill: $secondary;
        }
    }

    &--flipped {

        #{$this}__title {
            text-align: right;

            .highlight {
                margin-left: auto;
            }
        }

        #{$this}__description {
            margin-left: auto;
            text-align: right;
        }

        #{$this}__link-container {
            flex-direction: row-reverse;
            justify-content: flex-start;
        }

        #{$this}__chevron {
            right: auto;
            left: 0;
            transform: rotateY(180deg);
        }
    }

    @media (max-width: $break-desktop) {

        &__title {
            font-size: 5rem;
        }

        &__description {
            font-size: 1.6rem;
        }
    }

    @media (max-width: $break-tablet) {

        &__title {
            font-size: 3.6rem;
        }
    }
}
