.tabs {
    position: relative;
 
    &__tab-list {
        display: flex;
        justify-content: center;
        align-items: stretch;
    }

    &__tab {
        min-width: 22rem;
        padding: 1.2rem $spacing;
        background-color: $dark-ui;
        color: $white;
        font-weight: $bold;
        font-size: 1.4rem;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
        transition: background-color $transition, color $transition;

        @include hover {
            background-color: $primary-hover;
        }
    
        &.is-active {
            background-color: $primary;
        }

        &.t-team-theme {
            
            @include hover {
                background-color: var(--team-theme-primary);
                color: var(--team-theme-text);
            }
        
            &.is-active {
                background-color: var(--team-theme-primary);
                color: var(--team-theme-text);
            }
        }
    }

    @media (max-width: $break-tablet) {

        &__tab {
            width: 50%;
            min-width: auto;
        }
    }

    @media (max-width: $break-phablet) {

        &__tab {
            font-size: 1.2rem;
        }
    }
}
