.over {
    margin-top: $spacing;
    padding-top: $spacing;
    border-top: 1px solid $darker-ui;
    color: $white;

    &__info {
        display: flex;
        flex-direction: column;
        margin-bottom: .4rem;
    }

    &__progress {
        @include title-font;
        line-height: .8;
        font-size: 1.2rem;
        margin-bottom: $spacing-s;
        color: $medium-ui;
    }

    &__progress-count {
        margin-right: .4rem;
        padding: 0 .4rem;
        background-color: $primary;
        color: $white;
    }

    &__bowler {
        text-transform: uppercase;
        font-weight: $bold;
        font-size: 1.4rem;
    }

    &__balls {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: $spacing;
        padding: $spacing-s 0;
        margin-bottom: $spacing-s;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
    
        &::-webkit-scrollbar-track {
            background: $dark-ui;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: $medium-ui;
        }
    }

    @media (max-width: $break-desktop) {

        &__balls {
            grid-gap: $spacing-s;
        }
    }

    @media (max-width: $break-tablet) {

        &__balls {
            grid-gap: 5%;
        }
    }

    @media (max-width: $break-tablet) and (min-width: $break-mobile) {
        display: flex;

        &__info {
            width: 25%;
            margin: 1rem .4rem 0 0;
        }

        &__balls {
            width: 75%;
        }
    }

    @media (max-width: $break-phablet) and (min-width: $break-mobile) {

        &__progress {
            font-size: 1rem;
        }

        &__bowler {
            font-size: 1.2rem;
        }

        &__balls {
            grid-gap: 2%;
        }
    }
}