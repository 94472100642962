.in-play {
    $this: &;
    display: grid;
    grid-gap: $spacing-s;
    grid-template-columns: [bowler] 33% [batters] 66%;
    margin-bottom: $spacing-l;

    &__bowler {

        #{$this}__player-container {
            grid-template-columns: 98%;
            grid-gap: 0;
        }
    }

    &__heading {
        display: flex;
        flex-direction: column;
        margin-bottom: $spacing-s;
        line-height: .85;
        font-size: 2.8rem;
        color: $white;
        transform-origin: bottom;
        transition: opacity .2s .1s ease;

        &--inactive {
            opacity: .5;
        }
    }

    &__heading-icon {
        width: 1.6rem;
        height: 1.6rem;
        margin-left: .4rem;
        fill: $secondary;
    }

    &__player-container {
        display: grid;
        grid-template-columns: 49% 49%;
        grid-gap: 2%;
    }

    @media (max-width: $break-phablet) {
        margin-bottom: 0;
        padding: $spacing 0;
        border-top: 1px solid $darker-ui;

        &__heading-text {
            font-size: 2.2rem;
            
            &--small {
                margin-bottom: .2rem;
                font-size: 1.4rem;
            }
        }
    }

    @media (max-width: $break-mobile) {

        &__heading-text {
            font-size: 1.8rem;
            
            &--small {
                margin-bottom: 0;
                font-size: 1.2rem;
            }
        }
    }
}