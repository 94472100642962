.picture {

    &__img {
        max-width: 100%;
    }
}

.object-fit-cover-picture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
