.players-list {
    $this: &;

    &__grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: $spacing;
    }

    @media (max-width: $break-tablet) {

        &__grid {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media (max-width: $break-phablet) {

        &__grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: $break-mobile) {

        &__grid {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}