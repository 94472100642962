.navigation {
    $this: &;
    width: $navigation-width;
    height: 100%;
    
    &__list {
        height: 100%;
        position: fixed;
        width: $navigation-width;
        display: flex;
        flex-direction: column;
        background-color: $black;
        z-index: $level-six;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }
    }

    &__item {

        &--home {

            #{$this}__link-icon {
                width: 4rem;
                height: 4rem;

                &--external {
                    display: none;
                }                
            }

            #{$this}__link-text {
                display: none;
            }
        }

        &--disabled {

            #{$this}__link {
                color: $dark-ui;
                user-select: none;
                cursor: not-allowed;
    
                &:after {
                    display: none;
                }
            }
        }
    }

    &__link {
        height: 8.8rem;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: $spacing-s;
        text-decoration: none;
        color: $white;
        font-weight: $bold;
        text-align: center;
        text-transform: uppercase;
        font-size: .8rem;
        line-height: 1.1;

        &::before { // bottom underline
            content: '';
            height: 1px;
            position: absolute;
            right: $spacing-s;
            bottom: 0;
            left: $spacing-s;
            background-color: $darker-ui;
        }

        &::after { // sliding background for hover
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $primary;
            transform: translateX(-100%);
            transition: transform $transition;
        }

        @include hover($focus: false) {
            
            &::after {
                transform: translateX(0);
            }
        }

        &--selected {
            
            &::after {
                transform: translateX(0);
            }
        }
    }

    &__link-icon-container,
    &__link-orbit-container {
        position: relative;
        z-index: $level-one;
    }

    &__link-icon {
        width: 3.2rem;
        height: 3.2rem;
        fill: currentColor;

        &--external {
            width: 1.2rem;
            height: 1.2rem;
            position: absolute;
            top: -.4rem;
            right: -1.2rem;
        }
    }

    &__link-text {
        position: relative;
        margin-top: $spacing-s;
        z-index: $level-one;
    }

    @media (max-width: $break-desktop) {
        width: $navigation-width-desktop;

        &__list {
            width: $navigation-width-desktop;
        }
    }

    @media (max-width: $break-tablet) {
        width: 100vw;
        height: $navigation-height-tablet;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        background: none;
        z-index: $level-six;

        &--match-complete {

            #{$this}__link-orbit::after {
                display: none;
            }  
        }

        &__background { // only displayed beneath tablet
            width: 840px;
            height: $navigation-height-tablet;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &__list {
            width: 100%;
            position: relative;
            flex-direction: row;
            background: none;
            overflow: visible;
            z-index: $level-one;
        }

        &__item {
            width: 100%;

            &--ball-by-ball {

                #{$this}__link {
                    
                    &--selected {
                        color: $primary;

                        #{$this}__link-icon--arrow { // up arrow
                            color: $white;
                        }
                    }
                }

                #{$this}__link-orbit-container {
                    width: 4.8rem;
                    height: 4.8rem;
                    position: absolute;
                    top: -2.6rem;
                    left: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transform: translate(-50%);
                    background: linear-gradient(to bottom, $primary, $primary-hover);
                    border-radius: 100%;
                }

                #{$this}__link-orbit {
                    position: absolute;
                    top: .4rem;
                    right: .4rem;
                    bottom: .4rem;
                    left: .4rem;
                    border-radius: 100%;
                    border: 2px solid $white;
                    animation: spin 5s linear infinite;
        
                    &::after { // ball that sticks to the orbit
                        content: '';
                        width: .8rem;
                        height: .8rem;
                        position: absolute;
                        top: -.4rem;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: $white;
                        border-radius: 100%;
                        box-shadow: 0 0 6px 1px $white;
                    }
                }

                #{$this}__link-icon-container { // container for up arrow
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                }

                #{$this}__link-icon {
                    fill: $white;
                    margin-top: 0;

                    &--arrow {
                        fill: currentColor;
                    }
                }
            }

            &--disabled {

                #{$this}__link-orbit-container {
                    background: linear-gradient(to bottom, $darkest-ui, $dark-ui);
                }

                #{$this}__link-orbit {
                    border-color: currentColor;
                    
                    &::after {
                        display: none;
                    }
                }

                #{$this}__link-icon {
                    fill: currentColor;
                }
            }
        }

        &__link {
            height: $navigation-height-tablet;
            color: $medium-ui;
            transition: color $transition;

            &::before,
            &::after {
                display: none;
            }

            &--selected {
                background: none;
                color: $white;

                #{$this}__link-icon {
                    fill: $white;
                }
            }
        }

        &__link-icon-container {
            width: 3.6rem;
            height: 3.6rem;
        }

        &__link-icon {
            fill: $medium-ui;
            transition: fill $transition;

            &--arrow { // only displayed on the "ball-by-ball" link
                width: 1rem;
                height: 1rem;
                fill: $white;
            }
        }

        &__link-text {
            margin-top: .4rem;
        }
    }

    @media (max-width: $break-mobile) {

        &__link {
            padding: .4rem;
            font-size: .7rem;
        }
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
