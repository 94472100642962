.ball-by-ball {
    height: calc(100vh - #{$scorebox-height});
    max-width: $wrapper-width;
    margin: 0 auto;

    &__grid {
        display: grid;
    }
    
    @media (min-width: $break-tablet) {
        
        &__grid {
            height: 100%;
            grid-template-columns: [ball-by-ball] 32.5% [in-play] 67.5%;
        }
    
        &__grid-column {
            height: 100%;
            padding: $spacing-l $spacing-s;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
    
    @media (min-width: $break-desktop) {
        
        &__grid-column {
            grid-template-columns: [ball-by-ball] 30% [in-play] 70%;
            align-items: center;
            padding: $spacing-l $spacing;
        }
    }

    @media (max-width: $break-tablet) {
        height: auto;

        &__grid-column {
            order: 0;
            padding-top: $spacing;
            padding-right: $spacing;
            padding-left: $spacing;

            &--left {
                order: 1; // swap the display so the left hand column is second at this breakpoint
                position: relative;
                top: 0;
                overflow-y: visible;
            }
        }
    }

    @media (max-width: $break-phablet) {

        &__grid-column {
            padding-top: $spacing-s;
            padding-right: $spacing-s;
            padding-left: $spacing-s;
            
            &--left {
                padding-top: 0;
            }
        }
    }
}