.team-promo {
    $this: &;

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-right: $spacing;
        z-index: $level-one;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        padding: $spacing-l;
        background-color: var(--team-theme-primary);
        background-size: var(--team-theme-bg-pattern-size);
        background-position: center;
    }

    &__title {
        width: 90%;
        margin-bottom: $spacing-l;
        font-size: 5rem;
        color: var(--team-theme-text);
        line-height: var(--team-theme-title-line-height);
        font-family: var(--team-theme-title-font);
        text-transform: var(--team-theme-title-case);
        letter-spacing: var(--team-theme-title-letter-spacing);
    }

    &__link-container {
        display: flex;
        margin-top: auto;
    }

    &__link.button {
        background-color: var(--team-theme-tertiary);
    }

    &__logo {
        width: 21.4rem;
        height: 21.4rem;
        position: relative;
        align-self: center;
        flex-shrink: 0;

        /**
        * As this is an embeddable widget from thehundred.com the logo img src is a relative file path that doesn't exist in the MC
        * Hide the element and use an after instead
        */

        img {
            display: none;
        }

        &::after {
            // team logo
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    @media (max-width: $break-tablet) {

        &__container {
            padding: $spacing * 1.5;
        }

        &__title {
            width: 100%;
        }

        &__logo {
            display: none;
        }
    }

    @media (max-width: $break-phablet) {

        &__container {
            padding: $spacing;
        }

        &__title {
            font-size: 3.2rem;
        }
    }

    &.t-birmingham-phoenix {

        #{$this}__title {
            color: var(--team-theme-tertiary);
        }

        #{$this}__logo::after {
            // as the team logos are provided by a relative file path in the img src, we need to replace them with an pseudo element pointed at locally sourced team logos
            background-image: url(../../i/team-logos/birmingham-phoenix.svg);
        }
    }

    &.t-london-spirit {

        #{$this}__container {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right;
        }

        #{$this}__title {
            font-size: 9.2rem;
        }

        #{$this}__logo::after {
            // as the team logos are provided by a relative file path in the img src, we need to replace them with an pseudo element pointed at locally sourced team logos
            background-image: url(../../i/team-logos/london-spirit-white.svg);
        }

        @media (max-width: $break-phablet) {

            #{$this}__container {
                background-image: none;
            }

            #{$this}__title {
                font-size: 4.4rem;
            }
        }
    }

    &.t-manchester-originals {

        #{$this}__title {
            color: var(--team-theme-secondary);
        }

        #{$this}__logo::after {
            // as the team logos are provided by a relative file path in the img src, we need to replace them with an pseudo element pointed at locally sourced team logos
            background-image: url(../../i/team-logos/manchester-originals-white.svg);
        }

        @media (max-width: $break-phablet) {

            #{$this}__container {

                &::after {
                    content: '';
                    height: 40%;
                }
            }
        }
    }

    &.t-northern-superchargers {

        #{$this}__title {
            font-size: 8rem;
            text-transform: none;
            line-height: 1.1;
        }

        #{$this}__logo::after {
            // as the team logos are provided by a relative file path in the img src, we need to replace them with an pseudo element pointed at locally sourced team logos
            background-image: url(../../i/team-logos/northern-superchargers.svg);
        }

        @media (max-width: $break-phablet) {

            #{$this}__container {
                background-image: none;
            }

            #{$this}__title {
                font-size: 3.8rem;
            }
        }
    }

    &.t-oval-invincibles {

        #{$this}__container.team-background {
            overflow: hidden;
            position: relative;
            background-image: none;

            &::before,
            &::after {
                content: '';
                width: 50rem;
                height: 68rem;
                position: absolute;
                background-repeat: no-repeat;
                background-size: cover;
            }

            &::before {
                background-image: url(../../i/team-backgrounds/oval-invincibles2.svg);
                top: 50%;
                right: -10%;
                transform: translateY(-50%);
            }

            &::after {
                background-image: url(../../i/team-backgrounds/oval-invincibles1.svg);
                transform: rotate(90deg) translateX(100%);
                bottom: 0;
                right: 20%;
            }
        }

        #{$this}__title {
            width: 50%;
        }

        #{$this}__logo::after {
            // as the team logos are provided by a relative file path in the img src, we need to replace them with an pseudo element pointed at locally sourced team logos
            background-image: url(../../i/team-logos/oval-invincibles-black.svg);
        }

        @media (max-width: $break-tablet) {

            #{$this}__container {

                &::before,
                &::after {
                    display: none;
                }
            }

            #{$this}__title {
                width: 100%;
            }
        }
    }

    &.t-southern-brave {

        #{$this}__logo::after {
            // as the team logos are provided by a relative file path in the img src, we need to replace them with an pseudo element pointed at locally sourced team logos
            background-image: url(../../i/team-logos/southern-brave-black.svg);
        }
    }

    &.t-trent-rockets {

        #{$this}__container {
            background-image: url(../../i/team-backgrounds/trent-rockets2.svg);
        }

        #{$this}__logo::after {
            // as the team logos are provided by a relative file path in the img src, we need to replace them with an pseudo element pointed at locally sourced team logos
            background-image: url(../../i/team-logos/trent-rockets-black.svg);
        }
    }

    &.t-welsh-fire {

        #{$this}__logo::after {
            // as the team logos are provided by a relative file path in the img src, we need to replace them with an pseudo element pointed at locally sourced team logos
            background-image: url(../../i/team-logos/welsh-fire-white.svg);
        }
    }
}

.articleWidget .team-promo {
    // for when this appears in an article
    margin-left: -$article-padding;

    .team-promo__wrapper {
        padding: 0;
    }

    @media (max-width: $break-desktop) {
        margin-left: -$article-padding-desktop;
    }

    @media (max-width: $break-phablet) {
        margin: 0 #{-$article-padding-phablet};
    }
}
