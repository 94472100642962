.dummy-data-toggle {
    $this: &;
    position: fixed;
    bottom: 1.3rem;
    right: 1.3rem;
    font-size: 1rem;
    font-weight: $bold;
    z-index: $level-six;

    &.is-open {

        #{$this}__list {
            transform: scale(1);
            pointer-events: all;
        }
    }

    &__toggle {
        width: 4.2rem;
        height: 4.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background-color: #ee6c4d;
        text-transform: uppercase;
        color: #293241;
        box-shadow: -1px 1px 3px rgba($black, .2);
        transition: background-color $transition;

        &:hover {
            background-color: darken(#ee6c4d, 5%);
        }
    }

    &__icon {
        fill: currentColor;

        &--ball {
            width: 3.6rem;
            height: 3.6rem;
        }

        &--close {
            width: 1.8rem;
            height: 1.8rem;
        }
    }

    &__list {
        min-width: 18.2rem;
        position: absolute;
        bottom: 3rem;
        right: 3rem;
        transform: scale(0);
        border-radius: 8px;
        background-color: $white;
        box-shadow: 0 1px 2px rgba($black, .2);
        transform-origin: bottom right;
        pointer-events: none;
        transition: transform .2s ease;
    }

    &__list-title {
        padding: .1rem .6rem;
        white-space: nowrap;
    }

    &__button {
        width: 100%;
        padding: .5rem .8rem;
        text-transform: uppercase;
        white-space: nowrap;
        background: none;
        color: $medium-ui;
        transition: color $transition;

        &.is-active,
        &:hover {
            color: $black;
        }
    }

    &__active-icon {
        width: 1.2rem;
        height: .8rem;
        margin-left: .4rem;
        fill: currentColor;
    }

    @media (max-width: $break-tablet) {
        bottom: 7.8rem;
        right: auto;
        left: 1.3rem;

        &__list {
            right: auto;
            left: 3rem;
            transform-origin: bottom left;
        }
    }
}
