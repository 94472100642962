.tooltip {
    @include copy-font;
    position: absolute;
    bottom: calc(100% + .2rem);
    left: 50%;
    padding: $spacing-s $spacing;
    background-color: $white;
    border-radius: .4rem;
    box-shadow: 0 1px 3px rgba($black, .2);
    white-space: nowrap;
    font-size: 1.2rem;
    color: $text-colour;
    transform: translateX(-50%) scale(0);
    transform-origin: bottom;
    transition: transform $transition;

    &::after { // caret
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        top: 100%;
        left: 50%;
        border: .8rem solid transparent;
        border-top-color: $white;
        transform: translateX(-50%);
        filter: drop-shadow(0 1px 1px rgba($black, .2));
    }

    &.is-active {
        transform: translateX(-50%) scale(1); // this can also be used as the hover value
    }

    &--bottom {
        top: calc(100% + .4rem);
        bottom: auto;
        transform-origin: top;

        &::after {
            top: auto;
            bottom: 100%;
            border-top-color: transparent;
            border-bottom-color: $white;
        }
    }

    &--left {
        left: $spacing-s;
        transform: translateX(0) scale(0);
        transform-origin: bottom left;

        &::after {
            transform: none;
            left: $spacing-s;
        }

        &.is-active {
            transform: translateX(0) scale(1); // this can also be used as the hover value
        }
    }

    &--right {
        left: auto;
        right: $spacing-s;
        transform: translateX(0) scale(0);
        transform-origin: bottom right;

        &::after {
            transform: none;
            left: auto;
            right: $spacing-s;
        }

        &.is-active {
            transform: translateX(0) scale(1); // this can also be used as the hover value
        }
    }
}
