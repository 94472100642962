// stylelint-disable rule-empty-line-before, selector-max-universal, selector-max-type, selector-no-qualifying-type

* {
    box-sizing: border-box;

    @media (min-width: $break-tablet) {

        &::-webkit-scrollbar {
            width: 11px;
            height: 11px;
        }

        &::-webkit-scrollbar-track {
            background: $darker-ui;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0;
    margin-bottom: 0;
}

figure {
    margin: 0;
}

ol,
ul {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;

    li {
        list-style-type: none;
    }
}

cite {
    font-style: normal;
}

html {
    font-size: 62.5%;
    background: $black;
}

body {
    color: $text-colour;
    font-size: 1.8rem;
    // overflow-x: hidden;
}

.body-content {
    padding-top: $spacing;

    @media (max-width: $break-desktop) {
        // overflow-x: hidden;
    }
}

svg.icon {
    width: 2rem;
    height: 2rem;
    display: block;
    fill: currentColor;
}

button {
    display: block;
    border: 0;
    outline: 0;
}

a {

    @include hover {
        text-decoration: none;
    }
}

img {
    max-width: 100%;
}

// stylelint-enable
