.embeddable-video {
    $this: &;
    @include link-reset;
    position: relative;
    display: block;
    margin-left: -$article-padding;

    video {
        width: 100%;
    }

    .media__meta {
        display: none;
    }

    .video-js {
        width: 100%;
        height: auto;

        .vjs-tech {
            position: relative;
            height: auto;
        }

        .vjs-dock-text {
            display: none;
        }

        &.vjs-playing,
        &.vjs-has-started.vjs-paused { // when the video is playing, ensure it appears above any overlaying elements
            z-index: $level-two;
        }
    }

    &__details {
        display: block;
        margin-left: $article-padding;
        padding: .6rem 3rem .6rem $spacing;
        background-color: $primary;
        line-height: 1;
        color: $white;
        text-transform: uppercase;
        word-break: break-word;
    }

    &__title {
        @include copy-font;
        color: $white;
        font-weight: $regular;
        font-size: 1.4rem;
        line-height: 1.6;
        text-transform: none;
    }

    &__text {
        display: none;
    }

    &__date {
        display: none;
    }

    &__meta {
        position: absolute;
        top: $spacing-s;
        right: $spacing-s;
        padding: .2rem;
        font-size: 1.4rem;
        background-color: $primary;
    }

    @media (max-width: $break-desktop) {
        margin-left: -$article-padding-desktop;
    }

    @media (max-width: $break-phablet) {
        margin: 0 -$article-padding-phablet;

        &__details {
            margin: 0 $spacing 0 0;
            padding-left: $article-padding-phablet;
        }
    }
}
