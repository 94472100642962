.chase-bar {
    $this: &;
    --chase-bar-height: 2.8rem;
    --opposing-team-width: 4.2rem;
    width: 100%;
    height: var(--chase-bar-height);
    position: absolute;
    bottom: -1.4rem;
    display: flex;
    align-items: center;
    background-color: $black;
    color: $white;
    box-shadow: 0 0 4px 0px rgba($black, 0.5);
    z-index: $level-one;
    transition: transform .3s .15s ease; // transition controlled when parent widget appears
    
    &__text {
        @include title-font;
        flex-shrink: 0;
        padding: 0 $spacing;
        font-size: 2.2rem;
        line-height: .8;
    }

    &__progress {
        width: 100%;
        height: 2.4rem;
        position: relative;
        margin: .2rem 0;
        background-color: $darker-ui;
    }
    
    &__progress-inner {
        min-width: 0%;
        max-width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(to right, $primary-hover, $primary);
        will-change: width;
        transition: width .6s cubic-bezier(.22, 1, .36, 1);

        &::after { // white line at the end of the progress bar
            content: '';
            width: .6rem;
            position: absolute;
            top: -.4rem;
            right: -.3rem;
            bottom: -.4rem;
            background-color: $white;
            box-shadow: 0 0 5px 1px rgba($black, 0.4);
        }
    }

    &__target-icon {
        width: 2.4rem;
        height: 2.4rem;
        fill: $white;
        position: absolute;
        top: 0;
        right: -1.2rem;
        z-index: $level-one;
        filter: drop-shadow(0 0 1px rgba($black, .5));
    }

    &__target {
        flex-shrink: 0;
        padding: 0 0 0 $spacing;
        color: $primary;
        text-transform: uppercase;
        font-size: 1.6rem;
        line-height: 1;
        font-weight: $bold;
    }

    &__target-text {
        margin-right: .4rem;
    }

    &__target-score--zero {
        color: rgba($primary, .4);
    }

    &__opposing-team {
        width: var(--opposing-team-width);
        height: var(--opposing-team-width);
        flex-shrink: 0;
        position: relative;
        top: 0;
        right: -$spacing-s;
        background-color: $black;
        border-radius: 100%;
        box-shadow: 0 0 4px 0px rgba($black, 0.5);

        &.t-southern-brave,
        &.t-trent-rockets {

            #{$this}__team-badge {
                width: calc(var(--opposing-team-width) - 1.2rem);
                height: calc(var(--opposing-team-width) - 1.2rem);
                top: .6rem;
                right: .6rem;
            }
        }
    }

    &__team-badge {
        width: calc(var(--opposing-team-width) - .8rem);
        height: calc(var(--opposing-team-width) - .8rem);
        position: absolute;
        top: .4rem;
        right: .4rem;
    }

    @media (max-width: $break-tablet) {
        --chase-bar-height: 1.8rem;
        --opposing-team-width: 4rem;
        width: 100%;
        position: absolute;
        bottom: -1rem;
        border-radius: 0 2rem 2rem 0;
        box-shadow: 0 0 2px 1px rgba($darker-ui, .3);

        &__text {
            font-size: 1.1rem;
            letter-spacing: .2px;
            padding: 0 $spacing-s;
        }

        &__progress {
            height: 1.6rem;
        }

        &__progress-inner {

            &::after {
                width: .3rem;
                right: -.1rem;
            }
        }

        &__target-icon {
            width: 2.1rem;
            height: 2.1rem;
            top: -.2rem;
            right: -1rem;
        }

        &__target {
            color: $white;
            font-size: 1rem;
        }

        &__target-text {
            margin-right: .2rem;
        }
        
        &__target-score--zero {
            color: rgba($white, .4);
        }

        &__opposing-team {
            right: 0;
            box-shadow: 0 0 3px rgba($black, 0.2);
        }
    }
}