.ball {
    $this: &;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: $level-two;
    cursor: help;
        
    @include hover() {
        
        #{$this}__tooltip {
            transform: translateX(-50%) scale(1);

            &--left,
            &--right {
                transform: translateX(0) scale(1);
            }
        }
    }

    &__ball {
        height: 0;
        padding-top: 100%; // ensures the ball is an exact circle by maintain 1:1 ratio
        position: relative;
        margin-bottom: $spacing-s;
        border-radius: 100%;
        background-color: $dark-ui;
    }

    &__ball-orbit {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 100%;
        border: 3px solid $dark-ui;
        display: none; // only displayed on unbowled and the current ball

        &::after { // ball that sticks to the orbit
            content: '';
            width: .8rem;
            height: .8rem;
            position: absolute;
            top: -.5rem;
            left: 50%;
            transform: translateX(-50%);
            background-color: $white;
            border-radius: 100%;
            box-shadow: 0 0 6px 1px $white;
            display: none; // only displayed on the current ball
        }
    }
    
    &__score {
        @include title-font;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $white;
        font-size: 3rem; // stylelint-disable-line declaration-block-no-duplicate-properties
        font-size: 2.4vw; // stylelint-disable-line declaration-block-no-duplicate-properties
        font-size: clamp(1.4rem, 2.4vw, 3rem); // stylelint-disable-line declaration-block-no-duplicate-properties
        text-shadow: 1px 1px 1px rgba($black, .2);
    }

    &__icon { // only shown on the current ball
        width: 60%;
        height: 60%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__tooltip {
        min-width: 24rem;
        white-space: normal;
        color: $text-colour;
        left: 50%;
        
        &--left {
            left: calc(50% - #{$spacing});
        }

        &--right {
            right: calc(50% - #{$spacing});
            left: auto;
        }
    }

    &__number {
        @include title-font;
        width: var(--ball-width);
        line-height: .8;
        font-size: 1.1rem;
        text-align: center;
    }

    &--placeholder {
        cursor: default;

        #{$this}__ball-orbit {
            display: block;
        }

        #{$this}__ball {
            background-color: $black;
        }
    }

    &--current {
        cursor: default;

        #{$this}__ball-orbit {
            display: block;
            border-color: $secondary;
            animation: spin 5s linear infinite;

            &::after {
                display: block;
            }
        }
        
        #{$this}__ball {
            background-color: $black;
        }

        #{$this}__icon {
            fill: $secondary;
        }
    }

    &[class*="1"],
    &[class*="2"],
    &[class*="3"] {

        #{$this}__ball {
            background-color: $darker-ui;
        }
    }

    &[class*="4"],
    &[class*="5"],
    &[class*="6"] {

        #{$this}__ball {
            background-color: rgba($primary, .8);
            box-shadow: 0 0 16px 0 $primary, 0 3px 3px 0 rgba($black, 0.5);
        }
    }

    &[class*="6"] {

        #{$this}__ball {
            background-color: $primary;
        }
    }

    &[class*="Lb"],
    &[class*="B"],
    &[class*="Wd"],
    &[class*="Nb"] {

        #{$this}__score {
            font-size: 2.6rem; // stylelint-disable-line declaration-block-no-duplicate-properties
            font-size: 1.8vw; // stylelint-disable-line declaration-block-no-duplicate-properties
            font-size: clamp(1.4rem, 1.8vw, 2.6rem); // stylelint-disable-line declaration-block-no-duplicate-properties
        }
    }

    &[class*="wicket"] { // necessary to use wildcard to keep specificity consistent

        #{$this}__ball {
            background: none;
            border-radius: 0;
            border-top: 2px solid $primary;
            border-bottom: 2px solid $primary;

            &::after { // pink strikethrough line
                content: '';
                width: 70%;
                height: .8rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-52%, -36%) rotate(6deg);
                background-color: $primary;
                z-index: $level-one;
            }
        }

        #{$this}__score {
            font-size: 4.2rem; // stylelint-disable-line declaration-block-no-duplicate-properties
            font-size: 3.2vw; // stylelint-disable-line declaration-block-no-duplicate-properties
            font-size: clamp(2.4rem, 3.2vw, 4.2rem); // stylelint-disable-line declaration-block-no-duplicate-properties
        }
    }

    @media (max-width: $break-tablet) {

        &__score {
            font-size: 8rem; // stylelint-disable-line declaration-block-no-duplicate-properties
            font-size: 6.2vw; // stylelint-disable-line declaration-block-no-duplicate-properties
            font-size: clamp(3rem, 6.2vw, 8rem); // stylelint-disable-line declaration-block-no-duplicate-properties
        }

        &[class*="Lb"],
        &[class*="B"],
        &[class*="Wd"],
        &[class*="Nb"] {

            #{$this}__score {
                font-size: 6.4rem; // stylelint-disable-line declaration-block-no-duplicate-properties
                font-size: 4.8vw; // stylelint-disable-line declaration-block-no-duplicate-properties
                font-size: clamp(2.2rem, 4.8vw, 6.4rem); // stylelint-disable-line declaration-block-no-duplicate-properties
            }
        }

        &[class*="wicket"] {

            #{$this}__score {
                font-size: 9rem; // stylelint-disable-line declaration-block-no-duplicate-properties
                font-size: 8vw; // stylelint-disable-line declaration-block-no-duplicate-properties
                font-size: clamp(3.8rem, 9vw, 11rem); // stylelint-disable-line declaration-block-no-duplicate-properties
            }
        }
    }

    @media (max-width: $break-tablet) and (min-width: $break-phablet) {

        &__ball {
            margin-bottom: $spacing;
        }

        &__ball-orbit::after {
            width: 1.2rem;
            height: 1.2rem;
            top: -.6rem;
        }

        &__number {
            font-size: 1.8rem;
        }
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}