.social-follow {
    margin-bottom: 1.6rem;

    &__title {
        display: inline-block;
        font-size: 1.2rem;
        vertical-align: middle;
    }

    &__item {
        margin: 0;
        color: #fff;
        display: inline-block;
        vertical-align: middle;
        width: 3.2rem;
        height: 3.2rem;
        position: relative;

        .icon {
            width: 2rem;
            height: 2rem;
            fill: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &--facebook {
            background-color: $facebook;
        }

        &--twitter {
            background-color: $twitter;
        }

        &--youtube {
            background-color: $youtube;
        }

        &--instagram {
            background: $instagram-gradient;
        }
    }
}
