.post-match-header {
    $this: &;

    &__background {
        position: relative;
        background-color: $black;
        background-image: url(../../../../../../i/elements/post-match-header-chevron.svg);
        background-size: 50%;
        background-position: 120%;
        background-repeat: no-repeat;
        overflow: hidden;
    }

    &__content {
        min-height: 30rem;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 $spacing;
        overflow: hidden;
        z-index: $level-one;
    }
    
    &__title {
        width: 60%;
        position: relative;
        flex-shrink: 0;
        padding: $spacing-l $spacing-s $spacing-l 0;
        color: $white;
        font-size: 8rem;
        line-height: .8;
        z-index: $level-one;

        span {
            display: block;
            font-size: 3.2rem;
            margin-top: $spacing;
        }
    }
    
    &__player-image {
        width: 32rem;
        height: 48rem; // 2:3
        left: auto;
    }
    
    &__img { // the img element for the player
        object-position: top;
    }

    &__team-badge {
        width: 22rem;
        margin: $spacing-l 0;
        margin-right: 3rem;
    }

    @media (max-width: $break-desktop) {

        &__title {
            font-size: 6.4rem;

            span {
                font-size: 2.4rem;
            }
        }

        &__team-badge {
            width: 18rem;
        }
    }
    
    @media (max-width: $break-tablet) {

        &__content {
            min-height: 17rem;
        }
        
        &__title {
            font-size: 5.4rem;

            span {
                font-size: 2rem;
            }
        }

        &__team-badge {
            width: 14rem;
        }

        &__player-image {
            width: 22rem;
            height: 33rem; // 2:3
        }
    }

    @media (max-width: $break-phablet) {

        &__content {
            padding: 0 $spacing-s;
        }
        
        &__title {
            font-size: 3.4rem;

            span {
                font-size: 1.6rem;
            }
        }

        &__player-image {
            margin-bottom: -2rem;
        }

        &__team-badge {
            width: 10rem;
            margin-right: $spacing;
        }
    }

    @media (max-width: $break-mobile) {

        &__player-image {
            width: 14rem;
            height: 21rem; // 2:3
            top: auto;
        }
    }

    @media (max-width: $break-small-mobile) {

        &__title {
            font-size: 3rem;

            span {
                font-size: 1.4rem;
            }
        }

        &__team-badge {
            width: 7rem;
        }
    }

    &.t-team-theme {

        #{$this}__title {
            color: var(--team-theme-text);
        }

        @media (max-width: $break-tablet) {

            #{$this}__background.team-background {
                background-size: cover;
                background-position: 0;
            }
        }
    }

    &.t-birmingham-phoenix {

        #{$this}__background.team-background {
            background-image: url(../../../../../../i/team-backgrounds/birmingham-phoenix2.svg);
            background-size: 50%;
            background-position: right;
        }
    }

    &.t-london-spirit {

        #{$this}__title {
            font-size: 10rem;
        }

        @media (max-width: $break-desktop) {

            #{$this}__team-badge {
                height: 16rem;
            }
        }

        @media (max-width: $break-tablet) {

            #{$this}__title {
                font-size: 7rem;
            }

            #{$this}__team-badge {
                height: 12rem;
            }
        }

        @media (max-width: $break-phablet) {

            #{$this}__title {
                font-size: 5rem;
            }

            #{$this}__team-badge {
                height: 8rem;
            }
        }
    }

    &.t-manchester-originals {
        
        #{$this}__title {
            color: var(--team-theme-secondary);
        }
    }

    &.t-northern-superchargers {

        #{$this}__background.team-background {
            background-image: url(../../../../../../i/team-backgrounds/northern-superchargers1.svg);
        }

        @media (max-width: $break-tablet) {
            
            #{$this}__title {
                font-size: 6.4rem;
            }
        }

        @media (max-width: $break-phablet) {

            #{$this}__title {
                font-size: 5.2rem;
            }
        }
    }

    &.t-oval-invincibles {

        #{$this}__title {
            padding-bottom: 6rem;
            line-height: .9;
        }

        #{$this}__background.team-background {
            background-image: none;

            &::before {
                content: '';
                width: 50rem;
                height: 68rem;
                position: absolute;
                bottom: 0;
                right: 20%;
                transform: rotate(90deg) translateX(100%);
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url(../../../../../../i/team-backgrounds/oval-invincibles1.svg);
            }
        }

        @media (max-width: $break-phablet) {

            #{$this}__background.team-background::before {
                width: 32rem;
                height: 43rem;
                bottom: 2rem;
                right: 30%;
            }
        }
    }

    &.t-southern-brave {

        #{$this}__background.team-background {
            background-size: cover;
        }
    }

    &.t-trent-rockets {

        #{$this}__background.team-background {
            background-image: url(../../../../../../i/team-backgrounds/trent-rockets2.svg);
            background-size: cover;
            background-position: 0;
        }
    }

    &.t-welsh-fire {

        #{$this}__background.team-background {
            background-size: cover;
            background-position: 0;
        }
    }
}