.balls-by-over {
    --scroll-gradient-height: 8rem;
    position: relative;
    z-index: $level-one;

    &__header { // only displayed when there is no commentary to display
        padding-top: 0;
    }

    &__explainer { // only displayed under tablet
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        justify-content: flex-end;
        padding: $spacing 0 $spacing-s;
        border-top: 1px solid $darker-ui;
        z-index: $level-two;
    }

    &__innings {
        margin-top: $spacing-l;
        padding: $spacing 0 0;
        border-top: 1px solid $primary;
        color: $white;

        &:first-of-type {
            margin-top: 0;
        }
    }

    &__innings-title {
        text-align: center;
        margin-bottom: $spacing;
    }

    &__innings-team {
        display: flex;
        align-items: center;
    }

    &__innings-team-badge {
        width: 5.2rem;
        height: 5.2rem;
        flex-shrink: 0;
        margin-right: .4rem;
    }

    &__innings-team-name {
        @include title-font;
        overflow: hidden;
        text-overflow: ellipsis;
        word-spacing: 100vw;
        font-size: 2.4rem;
        line-height: .8;
        margin-right: .4rem;
    }

    &__innings-team-score {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
    }
    
    &__innings-team-balls,
    &__complete {
        font-size: .9rem;
        color: $medium-ui;
        text-transform: uppercase;
    }
    
    &__innings-team-runs {
        @include title-font;
        line-height: .8;
        font-size: 4rem;
        margin-bottom: .4rem;

        &--zero {
            color: $darker-ui;
        }
    }

    @media (max-width: $break-tablet) {

        &__innings:first-of-type {
            margin-top: $spacing;
        }
        
        &__overs {
            padding-top: $spacing-l;
        }
    }

    @media (max-width: $break-phablet) {
    
        &__innings-title {
            margin-bottom: $spacing-s;
        }
    
        &__innings-team-badge {
            width: 3.8rem;
            height: 3.8rem;
        }
    
        &__innings-team-name {
            font-size: 2.2rem;
        }
        
        &__innings-team-runs {
            font-size: 3.2rem;
        }
    }
}