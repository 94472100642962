$table-padding: 1rem;

.table {
    width: 100%;
    table-layout: fixed;

    &__head-cell {
        border-bottom: 1px solid $dark-ui;
        padding: $table-padding;

        &:first-of-type {
            border-right: 1px solid $dark-ui;
        }
    }

    &__body-row {

        &:nth-of-type( even ) {
            background: #fff;
        }
    }

    &__body-cell {
        padding: $table-padding;
        text-align: center;

        &:first-of-type {
            border-right: 1px solid $dark-ui;
        }
    }
}
