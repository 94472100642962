.scorebox-header {
    $this: &;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 $spacing;
    padding: $spacing-s;
    border-bottom: 1px solid $light-ui;
    font-size: 1.2rem;

    &__teams {
        margin-right: $spacing-s;
        line-height: 1;
        font-weight: $bold;
    }
    
    &__team {
        white-space: nowrap;
        text-transform: uppercase;
    }

    &__versus {
        margin: 0 .3rem;
    }

    &__icon-in-play {
        width: 4rem;
        height: 1rem;
        display: inline-block;
        flex-shrink: 0;
        margin-left: .4rem;
    }

    &__time {
        margin-right: .4rem;
        padding-right: .4rem;
        border-right: 2px solid $light-ui;
    }

    &__venue {
        text-align: right;
        line-height: 1;
        display: flex;
        align-items: center
    }

    &__venue-name {
        @include truncate(auto);
        max-width: 22rem;
        display: inline-block;
        vertical-align: bottom;
        font-weight: $bold;
    }

    &--live {
        
        #{$this}__team {
            color: $medium-ui;

            &.is-batting {
                color: $text-colour;
            }
        }
    }

    &__link-back {
        display: flex;
        align-items: center;
    }

    &__link-back-link {
        line-height: 1;
        font-weight: $bold;
        display: flex;
        justify-content: center;
        align-items: center;

        @include hover {
            text-decoration: underline;
        }
    }

    &__link-back-icon {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
    }

    @media (max-width: $break-tablet) {
        margin: 0;
        border-bottom: 1px solid $dark-ui;

        .in-play-icon__circle {
            fill: $white;
        }

        &--live {
        
            #{$this}__team {
                color: $medium-ui;
    
                &.is-batting {
                    color: $white;
                }
            }
        }

        &__link-back-link {
            color: $white;
        }

        &__link-back-icon {
            fill: $white;
        }
    }
    
    @media (max-width: $break-phablet) {
        font-size: 1rem;

        &__teams {
            display: flex;
            flex-wrap: wrap;
        }

        &__icon-in-play {
            width: 3.2rem;
            height: .8rem;
        }

        &__venue {
            display: block;

            &-name {
                position: relative;
                top: -0.1rem;
            }
        }

        &__time {
            line-height: 1.2rem;
        }
    }
}