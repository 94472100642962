.explainer {
    $this: &;
    @include title-font;
    position: relative;
    display: inline-flex;
    align-items: center;

    &__title {
        margin-right: .4rem;
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: $medium-ui;
        transition: color $transition;
    }
    
    &__icon {
        @include title-font;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 100%;
        background-color: $medium-ui;
        color: $black;
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.8rem;
        transition: background-color $transition;
    }
    
    &__tooltip {
        width: 50%;
        min-width: 22rem;
        text-transform: none;
        white-space: normal;
        line-height: 1.4;
        color: $text-colour;
    }

    &--tooltip {

        &.is-active,
        &:hover {
            cursor: help;
            
            #{$this}__title {
                color: $light-ui;
            }

            #{$this}__icon {
                background-color: $light-ui;
            }

            #{$this}__tooltip {
                transform: translateX(0) scale(1);
            }
        }
    }
    
    @media (max-width: $break-tablet) {
        
        &__title {
            @include copy-font;
            font-size: 1.2rem;
            line-height: 1.2rem;
        }
    
        &__icon {
            width: 1.2rem;
            height: 1.2rem;
            line-height: 1.2rem;
            font-size: 1rem;
        }
    }
    
    @media (max-width: $break-mobile) {
    
        &__title {
            font-size: 1rem;
        }   
    }
}
