.page-header {
    padding-top: $spacing;
    color: $white;

    &__title {
        margin-bottom: $spacing;
    }

    &__sub {
        display: block;
        margin-bottom: $spacing;
        line-height: 1.2;
    }

    &--live-stream {
        padding-top: $spacing-s;
    }

    @media (max-width: $break-phablet) {

        &__title {
            margin-bottom: $spacing-s;
            font-size: 2.8rem;
        }

        &__sub {
            font-size: 1.4rem;
        }
    }
}