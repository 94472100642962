.embeddable-promo { // markup dictated by embeddable widgets set in the CMS
    @include link-reset;
    position: relative;
    display: block;
    margin-left: -$article-padding;
    padding: $spacing * 1.5 $spacing * 1.5 $spacing * 3;
    overflow: hidden;
    background-color: $secondary;

    @include hover {
        // stylelint-disable-next-line declaration-no-important
        text-decoration: none !important; // stupid specific article styles

        .embeddable-promo__title .highlight {
            transform: translateX(2rem);
        }
    }

    &__title {
        font-size: 8rem;
        margin-bottom: $spacing;

        .highlight {
            padding: .2rem .4rem;
            background-color: $primary;
            color: $white;
            display: block;
            width: min-content;
            transition: transform $transition;
        }
    }

    &__description {
        max-width: 80%;
        position: relative;
        display: block;
        margin-bottom: $spacing;
        font-size: 2rem;
        line-height: 1.4;
        z-index: $level-one;
    }

    &__chevron {
        position: absolute;
        top: 85%;
        transform: rotate(12deg);
        left: -$article-padding;
        fill: $primary;
    }

    @media (max-width: $break-desktop) {
        margin-left: -$article-padding-desktop;

        &__title {
            font-size: 6rem;
        }

        &__description {
            max-width: none;
        }

        &__chevron {
            top: 90%;
            left: 0;
        }
    }

    @media (max-width: $break-tablet) {
        padding: $spacing;

        &__chevron {
            top: 80%;
            left: -2rem;
        }
    }

    @media (max-width: $break-phablet) {
        margin: 0 #{-$article-padding-phablet};

        &__description {
            margin-bottom: 3.2rem;
        }

        &__chevron {
            width: 150%;
        }
    }
}
