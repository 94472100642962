.social-share {
    $this: &;
    $button-diameter: 14rem;
    position: sticky;
    z-index: $level-one;

    &__options {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__button {
        @include button-reset;
        position: relative;

        @include hover {

            #{$this}__button-content--mask {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            }
        }

        &--twitter {

            #{$this}__button-content--mask {
                background-color: $twitter;
            }
        }

        &--facebook {

            #{$this}__button-content--mask {
                background-color: $facebook;
            }
        }

        &--whatsapp {

            #{$this}__button-content--mask {
                background-color: $whatsapp;
            }
        }

        &--copy { // the copy messsage option

            #{$this}__button-content--mask {
                background-color: $primary;
            }

            &.is-visible {

                #{$this}__copy-message {
                    visibility: visible;
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }
    }

    &__button-content {
        width: $button-diameter;
        height: $button-diameter;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $very-light-ui;

        .icon {
            width: 3rem;
            height: 3rem;
        }

        &--mask {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            box-shadow: none;
            transition: clip-path .5s ease;
            clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);

            svg {
                fill: $white;
            }
        }
    }

    &__copy-message {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: calc(100% + 1.2rem + .2rem); // width of elements + width of arrow + a bit of spacing
        padding: 5.6rem 0;
        background-color: $black;
        color: $white;
        font-weight: $bold;
        text-align: center;
        white-space: nowrap;
        opacity: 0;
        transform: translateX(-1rem);
        visibility: hidden;
        transition: opacity $transition, transform $transition, visibility $transition;

        &::before { // CSS arrow
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            top: 50%;
            right: 100%;
            transform: translateY(-50%);
            border: 1.2rem solid transparent;
            border-right-color: $black;
        }
    }

    @media (max-width: $break-wide) {
        $button-diameter: 10rem;

        &__button-content {
            width: $button-diameter;
            height: $button-diameter;
        }

        &__copy-message {
            padding: 4rem 0;
            font-size: 1.4rem;
        }
    }

    @media (max-height: 660px) { // when the browser is shorter than 660px
        $button-diameter: 8rem;

        &__button-content {
            width: $button-diameter;
            height: $button-diameter;
        }
    }

    @media (max-width: $break-desktop) {
        $button-diameter: 6rem;
        position: relative;
        top: 0;
        z-index: $level-three;

        &__toggle {
            @include button-reset;
            width: $button-diameter;
            height: $button-diameter;
            position: relative;
            background-color: $primary;
            z-index: $level-two;
            transition: background-color $transition;

            .icon {
                width: 2.2rem;
                height: 2.2rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                fill: $white;
            }
        }

        &__options {
            position: absolute;
            top: 100%;
            right: 0;
            display: flex;
            flex-direction: column;
        }

        &__option {
            pointer-events: none;
            transform: translateY(-1rem);
            opacity: 0;
        }

        &__button {

            &--twitter {

                .social-share__button-content {
                    background-color: $twitter;
                }
            }

            &--facebook {

                .social-share__button-content {
                    background-color: $facebook;
                }
            }

            &--whatsapp {

                .social-share__button-content {
                    background-color: $whatsapp;
                }
            }
        }

        &__button-content {
            width: $button-diameter;
            height: $button-diameter;
            transition: background-color $transition;

            .icon {
                width: 2.2rem;
                height: 2.2rem;
                fill: $white;
            }
        }

        &.animate-in & { // when the social share menu is expanded

            &__toggle {
                background-color: $primary-hover;
            }

            &__option {
                pointer-events: auto;
                animation: social-in .2s ease forwards;
                @include staggered-animation(6, .05);
            }
        }

        &.animate-out & {

            &__option {
                pointer-events: none;
                animation: social-out .2s ease backwards;
            }
        }
    }

    @media (max-width: $break-tablet) {
        $button-diameter: 4.4rem;

        &__toggle,
        &__button-content {
            width: $button-diameter;
            height: $button-diameter;
        }
    }

    @media (max-width: $break-phablet) {
        $button-diameter: 3.4rem;

        &__toggle,
        &__button-content {
            width: $button-diameter;
            height: $button-diameter;

            .icon {
                width: 1.8rem;
                height: 1.8rem;
            }
        }
    }
}

@keyframes social-in {

    from {
        transform: translateY(-1rem);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes social-out {

    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(-1rem);
        opacity: 0;
    }
}
