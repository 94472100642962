.lineups {
    $this: &;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $spacing-s;

    &__lineup {

        &.t-london-spirit,
        &.t-manchester-originals {
            
            #{$this}__team {
                background: linear-gradient(to right, var(--team-theme-primary), var(--team-theme-tertiary));
            }
        }
    }

    &__team {
        position: relative;
        margin-bottom: $spacing-s;
        padding: $spacing-s;
        background: linear-gradient(to right, var(--team-theme-primary), var(--team-theme-secondary));
        color: var(--team-theme-text);
        overflow: hidden;
    }

    &__team-badge {
        width: 5.2rem;
        height: 5.2rem;
        position: absolute;
        top: 50%;
        left: -0.8rem;
        transform: translateY(-50%);
    }

    &__team-name {
        @include truncate;
        @include copy-font;
        padding-left: 5.2rem;
        font-size: 2rem;
        line-height: 1;
    }

    &__player {
        display: flex;
        align-items: center;
        margin-bottom: $spacing-s;
        background-color: $dark-ui;
        color: $white;
    }
    
    &__player-img-container {
        width: 3.6rem;
        height: 3.6rem;
        position: relative;
        margin-left: .4rem;
    }
    
    &__player-img {
        object-position: top;
    }
    
    &__player-name {
        padding: .4rem $spacing-s;
    }

    &__player-name-last {
        margin-left: .4rem;
        font-weight: $bold;
    }

    @media (max-width: $break-desktop) {

        &__team-name {
            font-size: 1.8rem;
        }

        &__player-name {
            font-size: 1.6rem;
        }
    }

    @media (max-width: $break-phablet) {
        grid-template-columns: 100%; // one column

        &__player-name {
            font-size: 1.4rem;
        }
    }
}