.coach {
    position: relative;

    &__image-container {
        width: 10rem;
        height: 10rem;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &__description {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 5rem;
        padding: .4rem 5rem .4rem 11.6rem;
        text-transform: uppercase;
        color: var(--team-theme-text);
        font-size: 1.8rem;
        background-position: center;
    }

    &__name {
        font-weight: $bold;
        white-space: nowrap;
    }

    &__text {
        line-height: 1;
    }

    @media (max-width: $break-phablet) {

        &__image-container {
            width: 6rem;
            height: 6rem;
        }

        &__description {
            margin-top: 2.4rem;
            padding-left: 6.6rem;
            padding-right: 1.6rem;
            font-size: 1.2rem;
        }
    }
}