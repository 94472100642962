.pre-match {
    $this: &;
    position: relative;
    color: $white;
    overflow: hidden;
    
    &__chevron {
        position: absolute;
        right: -60%;
        bottom: 0;
        fill: $secondary;
    }
    
    &__main {
        position: relative;
        margin-bottom: $spacing;
        padding: $spacing-l $spacing;
    }
    
    &__subtitle {
        position: relative;
        display: inline-block;
        margin-bottom: $spacing-l;
        font-size: 4rem;

        &::after {
            content: '';
            width: 4rem;
            height: .2rem;
            position: absolute;
            left: 0;
            bottom: -.4rem;
            background-color: $white;
        }
    }

    &__title {
        max-width: 80%;
        position: relative;
        margin-bottom: $spacing-l;
        font-size: 9rem;
        line-height: .8;
    }

    &__team {
        display: inline-block;
    }

    &__vs {
        display: inline-block;
        margin: 0 .4rem;
        color: $primary;
    }

    &__info-container {
        position: relative;
        margin-bottom: $spacing;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__info {

        b {
            text-transform: uppercase;
        }
    }

    &--feed { // for the version that appears in the latest feed component

        #{$this}__main {
            padding: 0;
        }
    }

    @media (max-width: $break-desktop) {

        &__title {
            font-size: 7.8rem;
        }
    }

    @media (max-width: $break-tablet) {
        background-color: $white;

        &__chevron {
            top: -50%;
            right: -10rem;
            bottom: auto;
        }

        &__main {
            margin: $spacing-s 0;
            padding: $spacing;
            background-color: $black;
            overflow: hidden;
        }

        &__subtitle {
            font-size: 1.8rem;
            margin-bottom: $spacing;

            &::after {
                width: 3.2rem;
                height: .1rem;
            }
        }

        &__title {
            font-size: 3.6rem;
            margin-bottom: $spacing;
        }

        &__info-container {
            margin-bottom: $spacing;
        }

        &__info {
            font-size: 1.2rem;
        }

        &--feed {
            background-color: transparent;

            #{$this}__main {
                background-color: transparent;
            }
        }
    }
}