.in-play-icon {
    $this: &;
    width: 100%;

    &__circle {
        fill: $black;
        animation: fade-pulse 1.6s ease infinite;

        &--1 {
            animation-delay: 0s;
        }

        &--2 {
            animation-delay: .3s;
        }

        &--3 {
            animation-delay: .6s;
        }

        &--4 {
            animation-delay: .9s;
        }

        &--5 {
            animation-delay: 1.2s;
        }
    }

    &--light {

        #{$this}__circle {
            fill: $white;
            opacity: .8;
        }
    }
}
