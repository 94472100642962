.matches {
    $this: &;
    transition: opacity .35s ease, transform .35s ease;

    &-appear {
        opacity: 0;
        transform: translateY(3rem);
    }

    &-appear-done {
        opacity: 1;
        transform: translateY(0);
    }
    
    &__tabs {

        .tabs__tab-list {
            margin: $spacing 0;
        }
    }

    &__live {
        margin-bottom: $spacing-l;
    }

    &__in-play-icon {
        width: 7rem;
        height: 1.7rem;
        display: inline-flex;
        margin-left: $spacing;
    }

    &__list {

        &-appear {

            #{$this}__day {
                opacity: 0;
                transform: translateX(-3rem);
            }
        }
    
        &-appear-done {

            #{$this}__day {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }

    &__day {
        margin-bottom: $spacing-l;
        transition: opacity .3s ease, transform .3s ease;
        @include staggered-transition(25, .15);
    }

    &__day-title {
        position: relative;
        margin-bottom: $spacing;
        font-size: 3.2rem;
        color: $white;

        &::after { // small title underline
            content: '';
            width: 3.8rem;
            height: .2rem;
            position: absolute;
            bottom: -.6rem;
            left: 0;
            background-color: $white;
        }

        &--live {
            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
                display: none;
            }
        }
    }

    &__match {
        margin-bottom: $spacing-s;
    }

    &__no-matches {
        color: $white;
        text-align: center;
    }

    @media (max-width: $break-tablet) {

        &__day-title {
            text-align: center;

            &::after {
                width: 8rem;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    @media (max-width: $break-phablet) {

        &__day-title {
            font-size: 2.4rem;
        }
    }
}