@mixin touch-scroll-list {
    width: auto;
    display: flex;
    margin: 0 -1.2rem 0 -2rem;
    padding-left: $spacing;
    white-space: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;

    > li.touch-scroll-list__element { // stylelint-disable-line selector-no-qualifying-type
        width: 26rem;
        display: flex;
        flex-shrink: 0;
        white-space: normal;
        scroll-snap-align: center;
    }
}

.touch-scroll-list {

    &--phablet {

        @media (max-width: $break-phablet) {
            @include touch-scroll-list;
        }
    }
}
