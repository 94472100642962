/* Typography */

body {
    @include copy-font;
}

h1 {
    font-size: 3.5rem;
}

h2 {
    font-size: 3.2rem;
}

h3 {
    font-size: 2.8rem;
}

h4 {
    font-size: 2.6rem;
}

h5 {
    font-size: 2.4rem;
}

h6 {
    font-size: 2.2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include title-font;
    line-height: .9;
}

strong,
.bold {
    font-weight: $bold;
}

p {
    line-height: 1.6;
}

a {
    color: $text-colour;
    text-decoration: none;
}
