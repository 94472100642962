.pre-match-promos {

    &__promo {
        margin-bottom: $spacing;
    }

    @media (max-width: $break-tablet) {

        &__promo {
            margin-bottom: $spacing-s;
        }
    }
}