.score-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &__frame {
        width: 4rem;
        height: 4rem;
        fill: currentColor;
    }

    &__score {
        @include title-font;
        width: 4rem;
        line-height: 4rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 1.6rem;
    }

    @media (max-width: $break-tablet) {

        &__frame,
        &__score {
            width: 3.6rem;
            height: 3.6rem;
            line-height: 3.6rem;
            font-size: 1.4rem;
        }
    }
}