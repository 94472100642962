.live-stream-video {
    $this: &;
    width: 100%;
    height: 100vh;
    position: relative;
    z-index: $level-one;

    &__container {
        height: 100%;
    }

    &__video {
        width: 100%;
        height: 100%;

        video {
            width: 100%;
            height: 100%;
        }
    }

    @media (max-width: $break-tablet) {
        overflow: hidden;
        height: auto;
        background-color: $black;

        &.is-revealed {

            #{$this}__container {
                padding-top: $aspect-16-9;
                transition-delay: .1s;
            }

            #{$this}__video {
                opacity: 1;
                transition-delay: .2s;
            }

            #{$this}__poster {
                opacity: 0;
                pointer-events: none;
                transition-delay: 0s;
            }

            #{$this}__reveal {
                transform: translateY(-100%);
                transition-delay: .1s;
            }

            #{$this}__close {
                transform: translateY(0);
                transition-delay: .1s;
            }
        }
        
        &__container {
            height: 0;
            position: relative;
            padding-top: 6.4rem;
            transition: padding-top .3s 0s ease;
        }

        &__video {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            transition: opacity .3s 0s ease;
        }

        &__poster {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;
            transition: opacity .3s .3s ease;
        }

        &__poster-image {
            width: 100%;
            position: absolute;
            top: 50%;
            right: 0;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &__reveal {
            width: 100%;
            height: 6.4rem;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            background-color: rgba($black, .7);
            color: $white;
            border-bottom: 2px solid $primary;
            transition: transform .4s ease;
        }

        &__reveal-icon {
            width: 1.6rem;
            height: 1.6rem;
            margin-right: .6rem;
            fill: currentColor;
        }

        &__reveal-text {
            font-size: 1.2rem;
            text-transform: uppercase;
            font-weight: $bold;
        }

        &__close {
            width: 3.4rem;
            height: 3.4rem;
            position: absolute;
            top: 0;
            right: 0;
            background-color: rgba($black, .7);
            color: $white;
            transform: translateY(-100%);
            transition: transform .4s ease;
        }

        &__close-icon {
            width: 1.6rem;
            height: 1.6rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            fill: currentColor;
        }
    }
}