.embed-quote { // markup dictated by embeddable widgets set in the CMS
    position: relative;
    margin: 2.4rem 0;
    // padding: $spacing-l $spacing-l #{$spacing * 3} $spacing-xl;

    .embed-quote__text { // Not using SASS due to 'article__body p' overriding line height
        font-weight: $bold;
        font-size: 3.2rem;
        line-height: 1.6;
        margin-bottom: 0;
    }

    &__author {
        display: block;
        margin-top: $spacing;
        font-size: 2rem;
        font-weight: $bold;
        color: $primary;
    }

    &__icon.icon {
        width: 4.6rem;
        height: 4.6rem;
        position: absolute;
        top: -.4rem;
        left: -5.4rem;
        fill: $primary;
    }

    @media (max-width: $break-desktop) {

        &__icon.icon {
            width: 3rem;
            height: 3rem;
            top: 0;
            left: -3.8rem;
        }
    }

    @media (max-width: $break-phablet) {

        .embed-quote__text {
            font-size: 2.6rem;
        }

        &__author {
            font-size: 1.6rem;
        }

        &__icon.icon {
            display: none;
        }
    }
}
