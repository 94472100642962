.loader {
    $this: &;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    transition: opacity $transition;

    &__icon {
        width: 3.4rem;
        height: 3.4rem;
        fill: currentColor;
        animation: rotate 1.8s linear infinite;
    }

    &__text {
        margin-top: $spacing-s;
        font-size: 1.4rem;
        opacity: .8;
    }

    &--light {
        color: $dark-ui;
    }

    &--no-text {

        #{$this}__text {
            display: none;
        }
    }

    &.is-hidden {
        opacity: 0;
    }

    &.is-visible {
        opacity: 1;
    }
}
