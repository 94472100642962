.ball-count {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-l;
    color: var(--team-theme-text);
    display: none;

    &__icon {
        width: 3.4rem;
        height: 3.4rem;
        fill: currentColor;
    }

    &__number {
        @include title-font;
        line-height: .9;
        font-size: 3.8rem;
    }

    &__prepended { // any zeros that are prepended to the ball count
        opacity: .2;
    }

    @media (max-width: $break-tablet) {
        margin-bottom: $spacing;

        &__icon {
            width: 1.4rem;
            height: 1.4rem;
        }

        &__number {
            font-size: 1.8rem;
        }
    }
}