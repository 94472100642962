.team-card {
    $this: &;
    --card-min-height: 21.6rem;
    user-select: none;

    &.t-team-theme {

        .latest-feed__card-title--active {
            color: var(--team-theme-text);
        }
    }

    &__loaded {
        height: 100%;
    }

    &__content { // also has the team's background applied
        height: 100%;
        position: relative;
        overflow: hidden;
        z-index: $level-one;
    }

    &__text-container {
        width: 60%;
        padding: $spacing-l 0 $spacing-l 4rem;
    }

    &__text {
        color: var(--team-theme-text);
    }

    &__title {
        margin-bottom: $spacing;
        font-size: 7.8rem;
        line-height: .8;
    }

    &__subtitle {
        margin-bottom: $spacing;
        font-size: 4.8rem;
        line-height: .8;
    }

    &__image-container {
        width: 50%;
        max-width: 46rem;
        max-height: 110%;
        position: absolute;
        bottom: -10%;
        right: -10%;
    }

    &__player-img-container {
        height: 0;
        position: relative;
        padding-top: $aspect-2-3;
    }

    @media (max-width: $break-tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        &__loaded {
            opacity: 0;
            width: 100%;
            transition: opacity 2s ease;

            &--in-view {
                opacity: 1;
            }
        }

        &__content {
            width: 100%;
            min-height: var(--card-min-height);
        }

        &__text-container {
            padding: $spacing;
        }

        &__title {
            font-size: 3.8rem;
        }

        &__subtitle {
            @include copy-font;
            margin-bottom: $spacing-s;
            font-size: 1.8rem;
            line-height: 1.1;
            font-weight: $regular;
            text-transform: none;
        }

        &__image-container {
            width: 14.4rem; // defined by requested image size
            height: 21.6rem; // defined by requested image size
            bottom: 0;
            right: 0;
            transform: translateX(0);
        }
    }

    @media (max-width: $break-phablet) {

        &__title {
            font-size: 3.2rem;
        }

        &__subtitle {
            font-size: 1.4rem;
        }
    }

    &.t-birmingham-phoenix {

        #{$this}__content {
            background-size: contain;
            background-position: bottom -5rem right -5rem;
            background-repeat: no-repeat;
        }
    }

    &.t-manchester-originals {

        #{$this}__content {
            background-size: contain;
            background-position: bottom -8rem right -30rem;
            background-repeat: no-repeat;
        }
    }

    &.t-oval-invincibles {

        #{$this}__content {
            background-size: contain;
            background-position: bottom -25rem right -20rem;
            background-repeat: no-repeat;
        }

        @media (max-width: $break-tablet) {

            #{$this}__content {
                background-position: bottom -5rem right -5rem;
            }
        }
    }
}
