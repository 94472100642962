.match-header {
    $this: &;
    width: 100%;
    height: 8rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $spacing-s;
    background-color: $black;
    color: $white;
    z-index: $level-six;
    transition: transform .35s ease;

    &-appear {
        transform: translateY(-100%);
    }

    &-appear-done {
        transform: translateY(0);
    }

    &__left {
        display: flex;
        align-items: flex-start;
    }

    &__matches-link {
        @include link-reset;
        position: relative;
        display: flex;
        align-items: center;
    }
    
    &__matches-link-arrow {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: $spacing-s;
        fill: currentColor;
    }

    &__logo {
        width: 17rem;
        height: 2.8rem;
        margin-right: .8rem;
        fill: currentColor;
    }

    &__title {
        font-size: 4.1rem;
        line-height: .7;
        letter-spacing: -.6px;
    }
    
    &__rules-link {
        @include link-reset;
        width: 13.8rem;
        display: flex;
        align-items: center;
    }
    
    &__rules-link-text {
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: $bold;
        line-height: 1;
        text-align: right;
    }

    &__rules-link-icon {
        width: 2.8rem;
        height: 2.8rem;
        flex-shrink: 0;
        margin: 0 .4rem;
        fill: currentColor;
    }
    
    &__rules-link-arrow {
        width: 1.6rem;
        height: 1.6rem;
        flex-shrink: 0;
        fill: currentColor;
    }

    &__chevrons {
        position: absolute;
        bottom: 0;
        left: 0;
        fill: $primary;
    }

    &--matches {
        margin-bottom: $spacing;
    }

    @media (max-width: $break-tablet) {

        &--matches {
            
            #{$this}__logo {
                width: 17.2rem;
                height: 2.8rem;
            }
        }
    }

    @media (max-width: $break-phablet) {
        height: 7rem;
        padding: 0 $spacing-s;

        &__matches-link-arrow,
        &__rules-link-arrow {
            width: 1.2rem;
            height: 1.2rem;
        }

        &__logo {
            width: 14rem;
            height: 2.3rem;
        }

        &__title {
            font-size: 3.4rem;
        }

        &__chevrons {
            width: 196px;
            height: 10px;
        }
    }

    @media (max-width: $break-mobile) {
        height: 6rem;

        &__title {
            font-size: 2.5rem;
        }
    }
}