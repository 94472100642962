.advert {
    min-height: 2.4rem;
    position: fixed;
    bottom: $spacing;
    left: 50%;
    transform: translateX(-50%);

    &__close {
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        top: $spacing-s;
        right: $spacing-s;
        background-color: $primary;
        color: $white;
        z-index: $level-one;
    }

    &__close-icon {
        width: 1.2rem;
        height: 1.2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: currentColor;
    }

    @media (max-width: $break-tablet) {
        bottom: $navigation-height-tablet + ($spacing-l);
    }
}
