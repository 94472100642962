.match-report {
    $this: &;
    position: relative;
    display: flex;

    &__loader {
        padding: $spacing;
    }

    &__social {
        position: sticky;
        top: $scorebox-height;
        align-self: flex-start;
    }

    &__container {
        width: 100%;
        max-width: 96.4rem;
        margin: 0 auto;
        padding: $spacing-l $article-padding;
    }

    &__title {
        @include copy-font;
        position: relative;
        margin-bottom: $spacing-l;
        color: $black;
        font-size: 5.6rem;
        line-height: 1.2;
        text-transform: none;
    }

    &__summary {
        @include copy-font;
        font-weight: $bold;
        margin-bottom: $spacing-l;
        font-size: 2rem;
        line-height: 1.6;
        text-transform: none;
    }

    &__body {

        // stylelint-disable selector-max-type, declaration-no-important

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: $spacing;
        }

        p {
            line-height: 1.9;
            margin-bottom: 2.4rem;
        }

        a {
            font-weight: $bold;

            @include hover {
                text-decoration: underline;
            }
        }

        ol,
        ul {
            padding-left: 3.2rem;
            margin-bottom: 2.4rem;
            line-height: 1.6;

            li {
                list-style-type: inherit;
            }
        }

        .team-promo {
            margin-left: -$article-padding;

            .wrapper {
                padding: 0;
            }
        }

        .team-promo__link {
            @include hover {
                text-decoration: none;
            }
        }

        .articleWidget .instagram-post,
        .twitterThumb {
            display: flex;
            justify-content: center;
        }

        .articleWidget,
        .twitterThumb {
            margin: $spacing-l 0;

            .instagram-media,
            .twitter-tweet { // important used to override iframe styling
                width: 100% !important;
                max-width: 500px !important;
                min-width: 270px !important;
            }

            .embeddable-video__title {
                margin-bottom: 0;
            }

            &.left,
            &.left > .instagram-post {
                justify-content: flex-start;
            }

            &.center,
            &.full-width,
            &.center > .instagram-post,
            &.full-width > .instagram-post {
                justify-content: center;
            }

            &.right,
            &.right > .instagram-post {
                justify-content: flex-end;
            }
        }

        // instagram paragraph jingalo jangalo... styles has white-space nowrap property
        // let's override it so that it does not break mobile view when no instagram content rendered

        .instagram-post p {
            white-space: normal !important;
        }

        table {
            width: 100%;
            table-layout: fixed;
            margin-bottom: 2.4rem;

            thead {
                background-color: $very-light-ui;

                td {
                    font-weight: $bold;
                }
            }

            tbody {

                td {
                    border: 1px solid $very-light-ui;
                }
            }

            td {
                padding: $spacing-s;
                word-break: break-word;
                hyphens: auto;
            }
        }

        // stylelint-enable
    }

    @media (max-width: $break-desktop) {

        &__container {
            padding: $spacing-l $article-padding-desktop;
        }

        &__title {
            font-size: 4.2rem;
        }

        &__body {

            .team-promo {
                margin-left: -$article-padding-desktop;
            }
        }
    }

    @media (max-width: $break-tablet) {
        overflow: hidden; // ensures any larger article embeds don't break the page layout

        &__social {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    @media (max-width: $break-phablet) {

        &__container {
            padding: #{$spacing * 1.5} $article-padding-phablet;
        }

        &__title {
            padding-right: $spacing; // spaces title away from social dropdown
            font-size: 3.8rem;
        }

        &__body {

            .team-promo {
                margin: 0 #{-$article-padding-phablet};
            }
        }
    }

    @media (max-width: $break-mobile) {

        &__title {
            margin-bottom: $spacing;
            font-size: 3rem;
        }

        &__summary {
            margin-bottom: $spacing-s;
        }
    }
}
