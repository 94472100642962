.squads {
    padding-top: $spacing-l;

    @media (max-width: $break-tablet) {
        padding-top: $spacing;
        
        .tabs__selectors {
            margin: 0 -#{$spacing-s};
        }
    }
}

.squad {
    padding-bottom: $spacing;

    &__loader {
        margin-top: $spacing;
    }
    
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: $spacing;
    }

    @media (max-width: $break-phablet) {

        &__header {
            margin: $spacing-s 0;
        }
    }
}