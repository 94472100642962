@keyframes fade-pulse {

    0% {
        opacity: .2;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: .2;
    }
}

@keyframes rotate {

    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
