.win-predictor {
    $this: &;
    --chart-height: 6.4rem;
    width: 100%;
    margin-bottom: $spacing;
    color: $white;

    &__header { // Only rendered under tablet
        display: flex;
        align-items: baseline;
        margin-bottom: $spacing-s;
    }

    &__title { // Only rendered under tablet
        @include copy-font;
        font-weight: $bold;
        font-size: 1.2rem;
        margin-right: .4rem;
    }

    &__subtitle { // Only rendered under tablet
        font-size: 1.2rem;
        line-height: 1;
        text-transform: uppercase;
    }
    
    &__chart {
        height: var(--chart-height);
        display: flex;
        margin-bottom: $spacing;
    }

    &__bar {
        max-width: 100%;
        min-width: 0;
        height: 100%;
        position: relative;
        flex-shrink: 0;
        will-change: width;
        transition: width .6s cubic-bezier(.22, 1, .36, 1);

        &::after { // dividing line between bars
            content: '';
            width: .3rem;
            position: absolute;
            top: -.4rem;
            left: -.1rem;
            bottom: -.4rem;
            background-color: $white;
            box-shadow: 0 0 5px 1px rgba($black, 0.4);
        }

        &--home,
        &--away {
            background: linear-gradient(to right, var(--team-theme-primary), var(--team-theme-secondary));

            &.is-active,
            &:hover {
    
                #{$this}__tooltip {
                    transform: translateX(0) scale(1);
                }
            }
        }

        &--home::after {
            display: none;
        }

        &--tie {
            background: linear-gradient(to right, $darker-ui, $dark-ui);

            &.is-active,
            &:hover {
    
                #{$this}__tooltip {
                    transform: translateX(-50%) scale(1);
                }
            }

            #{$this}__info {
                padding-left: $spacing;
                color: $white;
            }
        }

        &.t-london-spirit,
        &.t-manchester-originals {
            background: linear-gradient(to right, var(--team-theme-primary), var(--team-theme-tertiary));
        }
    }

    &__bar-inner {
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    &__team-badge {
        width: calc(#{var(--chart-height)} + #{$spacing});
        height: calc(#{var(--chart-height)} + #{$spacing});
        position: absolute;
        top: 50%;
        left: -$spacing;
        transform: translateY(-50%);
    }

    &__info {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: calc(#{var(--chart-height)} + #{$spacing-s});
        color: var(--team-theme-text);
    }

    &__percent {
        @include title-font;
        position: relative;
        line-height: .8;
        font-size: 4.8rem;
    }

    &__text {
        white-space: nowrap;
        font-size: 1rem;
    }

    &__explainer {
        margin-left: auto;
    }

    @media (max-width: $break-phablet) {
        --chart-height: 3.2rem;
        margin-bottom: 0;
        padding: $spacing 0 $spacing-s;
        border-top: 1px solid $darker-ui;

        &__chart {
            margin-bottom: $spacing-s;
        }

        &__bar {

            &::after {
                width: .2rem;
                top: -.2rem;
                bottom: -.2rem;
            }

            &--home {

                #{$this}__tooltip {
                    left: 0;
                }
            }

            &--tie {

                #{$this}__info {
                    padding: 0 $spacing-s;
                }   
            }

            &--away {

                #{$this}__tooltip {
                    right: 0;
                }
            }
        }

        &__percent {
            font-size: 3.2rem;
            text-shadow: 1px 1px 1px rgba($dark-ui, .3);
        }
    }

    @media (max-width: $break-mobile) {

        &__title,
        &__subtitle {
            font-size: 1rem;
        }   
    }
}