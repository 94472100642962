.match-card {
    @include link-reset;
    $this: &;
    $date-container-width: 13rem;
    $date-container-width-desktop: 11rem;
    $button-container-width: 22rem;
    $double-header-width: 2.4rem; // this isn't used as the actual width of the double header, as it has been rotated, instead it is used for spacing of surrounding elements
    position: relative;
    display: flex;
    align-items: center;
    padding: $spacing;
    background-color: $white;
    transition: transform .25s ease;
    will-change: transform;

    @include hover {
        transform: translateY(-2px);
        
        #{$this}__button {
            background-color: $primary-hover;
            transform: translateY(-2px);
            box-shadow: -2px 2px 3px 1px rgba(0, 0, 0, .15);
        }
    }

    &__theme {
        width: $spacing;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: var(--team-theme-primary);
        background-repeat: no-repeat;
        background-size: var(--team-theme-bg-pattern-size);
        background-position: center;
        z-index: $level-one;
    }

    &__date-container {
        @include title-font;
        width: $date-container-width;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: $spacing + $double-header-width;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $white;
        color: $black;
        line-height: .95;
    }

    &__day,
    &__month {
        font-size: 2rem;
    }

    &__date {
        font-size: 4.8rem;
    }

    &__time {
        @include copy-font;
        margin-top: .8rem;
        font-size: 1.2rem;
    }

    &__logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: $date-container-width + $double-header-width + 3.2rem;
        padding-right: $spacing;
    }

    &__team-logo {
        width: 7.4rem;
        height: 7.4rem;
        margin: 0 $spacing;
    }

    &__description {
        flex: 1;
        color: $black;
        padding-right: $button-container-width - ( $spacing-s );
    }

    &__meta {
        display: block;
        margin-bottom: .4rem;
        font-size: 1.2rem;
    }

    &__gender {
        color: $primary;
        font-weight: $bold;
    }

    &__team-name {
        @include title-font;
        display: block;
        font-size: 3.2rem;
        line-height: 1;
        color: $black;
    }

    &__vs {
        margin-right: .4rem;
        color: $primary;
    }

    &__button-container {
        width: $button-container-width;
        height: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 $spacing;
        border-left: 2px solid $light-ui;
    }

    &__button {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.2rem $spacing;
        background-color: $primary;
        color: $white;
        text-transform: uppercase;
        font-size: 1.4rem;
        line-height: 2.4rem; // the same height as the tickets icon
        white-space: nowrap;
        transition: background-color $transition, transform $transition, box-shadow $transition;
    }

    &__button-icon {
        width: 2.4rem;
        height: 2.4rem;
        flex-shrink: 0;
        margin-right: .8rem;
        fill: currentColor;
    }

    &--final {

        @include hover {

            #{$this}__button {
                background-color: darken($final, 5%);
            }
        }

        #{$this}__theme,
        #{$this}__button {
            background-color: $final;
        }

        #{$this}__date-container,
        #{$this}__vs,
        #{$this}__gender {
            color: $final;
        }
    }

    @media (max-width: $break-desktop) {

        &__date-container {
            width: $date-container-width-desktop;
        }

        &__day,
        &__month {
            font-size: 1.8rem;
        }

        &__date {
            font-size: 4rem;
        }

        &__logo-container {
            padding-left: $date-container-width-desktop + $double-header-width;
            padding-right: $spacing-s;
        }

        &__team-logo {
            width: 5.5rem;
            height: 5.5rem;
            margin: 0 $spacing-s;
        }

        &__team-name {
            font-size: 2.6rem;
            min-width: 28rem;
        }
    }

    @media (max-width: $break-tablet) {
        flex-direction: column;
        padding: 0;

        &__theme {
            width: auto;
            height: $spacing;
            bottom: auto;
            right: 0;
        }

        &__date-container {
            width: auto;
            height: auto;
            position: relative;
            left: auto;
            flex-direction: row;
            margin: $spacing 0 $spacing;
            padding: .8rem 0 .2rem;
        }

        &__day,
        &__date,
        &__month {
            font-size: 2.6rem;
        }

        &__day,
        &__date {
            margin-right: .4rem;
        }

        &__time {
            width: 100%;
            position: absolute;
            top: 100%;
            left: 0;
            text-align: center;
            font-size: 1.4rem;
        }

        &__logo-container {
            width: 100%;
            max-width: none;
            position: relative;
            display: grid;
            grid-auto-flow: column;
            grid-template-rows: repeat(2, minmax(0, 1fr));
            grid-template-columns: repeat(2, minmax(0, 1fr));
            padding: 0;
        }

        &__team-logo {
            width: 10.4rem;
            height: 10.4rem;
            max-width: none;
            margin: 0 auto;
        }

        &__meta {
            margin-bottom: $spacing;
        }

        &__team-name {
            min-width: 0;
            padding: 0 3.2rem;
            text-align: center;
            font-size: 3.2rem;
            word-spacing: 100vw;
            line-height: .9;
        }

        &__vs {
            @include title-font;
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            margin: 0 auto;
            transform: translateY(-50%);
            text-align: center;
            font-size: 2.6rem;

            &::before, // top and bottom dividing line between teams
            &::after {
                content: '';
                width: 2px;
                height: 150%;
                background-color: $light-ui;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }

            &::before {
                bottom: 110%;
            }

            &::after {
                top: 110%;
            }
        }

        &__description {
            padding: $spacing-s $spacing-s 0;
            font-size: 1.1rem;
            text-align: center;
        }

        &__button-container {
            width: 100%;
            height: auto;
            position: relative;
            align-items: center;
            padding: 0 $spacing-s $spacing-s;
            text-align: center;
            border-left: none;
        }

        &__button {
            width: 75%;
            padding: .8rem 1.2rem;
        }

        @media (max-width: $break-phablet) {

            &__day,
            &__date,
            &__month {
                font-size: 2rem;
            }

            &__time {
                margin-top: .4rem;
            }

            &__team-logo {
                width: 7.4rem;
                height: 7.4rem;
            }

            &__team-name {
                font-size: 2rem;
                padding: 0 2rem;
            }

            &__vs {
                font-size: 2rem;
            }
        }

        @media (max-width: $break-mobile) {

            &__team-name {
                padding: 0;
                font-size: 1.6rem;
            }

            &__button {
                padding: .8rem;
                font-size: 1.2rem;
            }
        }
    }

    // specific team theming

    &.t-birmingham-phoenix {

        #{$this}__theme {
            background-size: 4440%;
        }

        @media (max-width: $break-tablet) {

            #{$this}__theme {
                background-size: cover;
            }
        }
    }

    &.t-london-spirit {

        #{$this}__theme {
            background-size: 50%;
            background-repeat: repeat;
            background-position: right;
        }

        @media (max-width: $break-tablet) {

            #{$this}__theme {
                background-size: cover;
            }
        }
    }

    &.t-manchester-originals {

        #{$this}__theme {
            background-position: top -6rem left -2.5rem;
            background-size: 3810%;
        }

        @media (max-width: $break-tablet) {

            #{$this}__theme {
                background-size: cover;
                background-position: center;
            }
        }
    }

    &.t-oval-invincibles {

        #{$this}__theme {
            background-size: 1930%;
            background-position: top -4.6rem left -2.6rem;
        }

        @media (max-width: $break-tablet) {

            #{$this}__theme {
                background-size: cover;
            }
        }
    }

    &.t-trent-rockets {

        #{$this}__theme.team-background {
            background-image: url(../../../../../i/team-backgrounds/trent-rockets2.svg);
            background-size: cover;
        }
    }

    &.t-welsh-fire {

        #{$this}__theme {
            background-size: auto 100%;
        }

        @media (max-width: $break-tablet) {

            #{$this}__theme {
                background-size: cover;
            }
        }
    }
}
