.social-share-simple {
    $this: &;
    display: flex;
    align-items: center;

    &__title {
        @include copy-font;
        font-size: 1.8rem;
        padding-right: .8rem;
        color: $white;
    }

    &__options {
        display: flex;
    }

    &__option {
        margin: 0 $spacing-s;

        &:first-child {
            margin-left: $spacing;
        }
    }

    &__button {
        display: block;
        position: relative;
        padding: $spacing-s;
        color: $white;

        &--twitter {
            color: $twitter;
        }

        &--facebook {
            color: $facebook;
        }

        &--whatsapp {
            color: $whatsapp;
        }

        &--copy {
            color: $primary;

            &.is-visible {

                #{$this}__copy-message {
                    visibility: visible;
                    opacity: 1;
                    transform: translate(-50%, 0);
                }
            }
        }
    }

    &__button-icon {
        width: 3rem;
        height: 3rem;
        fill: currentColor;
    }

    &__copy-message {
        position: absolute;
        bottom: 100%;
        left: 50%;
        padding: .4rem .8rem;
        font-size: 1.1rem;
        background-color: $primary;
        color: $white;
        font-weight: $bold;
        text-align: center;
        white-space: nowrap;
        opacity: 0;
        transform: translate(-50%, 1rem);
        visibility: hidden;
        transition: opacity $transition, transform $transition, visibility $transition;

        &::before { // CSS arrow
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            border: .4rem solid transparent;
            border-top-color: $primary;
        }
    }

    &--small {

        #{$this}__title {
            font-size: 1.4rem;
        }

        #{$this}__option {
            margin: 0 .4rem;

            &:first-child {
                margin-left: $spacing-s;
            }
        }

        #{$this}__button {
            padding: .6rem;
        }

        #{$this}__button-icon {
            width: 2.4rem;
            height: 2.4rem;
        }
    }

    @media (max-width: $break-tablet) {

        &__title {
            font-size: 1.4rem;
        }

        &__option {
            margin: 0 .4rem;

            &:first-of-type {
                margin-left: .8rem;
            }
        }

        &__button-icon {
            width: 2.2rem;
            height: 2.2rem;
        }
    }
}
