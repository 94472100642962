/**
 * Lazy Image requires an aspect ratio percentage as a padding-top to be set on a component level
 * e.g. a 16:9 image has the variable of $aspect-16-9 set on the .lazy-image class that equates to 56.25%
 */

.lazy-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &__placeholder {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $very-light-ui;
        overflow: hidden;

        &::before {
            // background logo
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: url(../../i/elements/placeholder.svg);
            background-size: 80%;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &--small {

            &::before {
                background-size: 60%;
            }
        }

        &--dark {
            background: $dark-ui;
        }

        &--no-image::before {
            display: none;
        }

        &--player::before {
            background-image: url(../../i/elements/player-shadow.svg);
            background-size: 55%;
            background-position: bottom;
        }

        &.team-player-background {
            background-color: $black;
            background-size: cover;
            background-position: top;

            &::before {
                display: none;
            }
        }
    }

    &__picture {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
        opacity: 0;
        transition: opacity .35s ease;

        &.is-loaded {
            opacity: 1;
        }
    }
}
