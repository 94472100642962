.app {
    position: relative;
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);

    &__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__fixed-header {
        position: sticky;
        top: 0;
        box-shadow: 0 0 2px 2px rgba($darker-ui, .3);
        z-index: $level-five;
    }

    &--no-nav {
        display: block;
    }

    @media (min-width: $break-tablet) {
        min-height: 100vh;

        &__navigation,
        &__main {
            height: 100%;
        }
    }
}
