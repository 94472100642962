.player-card {
    $this: &;
    width: 100%;
    position: relative;
    background: $dark-placeholder-gradient;

    &__image-container { // also includes the player card team background image
        height: 0;
        position: relative;
        overflow: hidden;
        padding-top: $aspect-2-3;
        background-image: url(../../i/team-player-backgrounds/default.jpg);
        background-size: cover;

        &::after { // bottom branded keyline
            content: '';
            height: 1.6rem;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: var(--team-theme-primary);
            transform: translateY(100%);
            transition: transform $transition;
        }
    }

    &__image {
        transition: transform $transition;
        width: 100%;
        height: 100%;
        object-fit: cover;

        &--silhouette {
            position: absolute;
            top: $spacing-s;
            left: 0;

            svg {
                height: 100%;
            }
        }
    }

    &__caption {
        position: absolute;
        bottom: 3.8rem;
        text-align: center;
        // align-self: center;
        width: 100%;
        color: $white;
        text-transform: uppercase;
        line-height: var(--team-theme-card-line-height);
    }

    &__forename,
    &__surname {
        font-family: var(--team-theme-title-font);
        text-transform: var(--team-theme-title-case);
        background-color: $black;
        padding: 0 .8rem;
    }

    &__forename {
        position: relative;
        font-size: 3rem;
    }

    &__surname {
        font-size: 4rem;
        color: var(--team-theme-primary);
    }

    &__role {
        background-color: var(--team-theme-primary);
        color: var(--team-theme-tertiary);
        font-family: var(--team-theme-title-font);
        font-size: 1.8rem;
        padding: 0 .4rem;
        text-transform: uppercase;
        display: block;
        width: fit-content;
        margin: 0 auto;
        position: relative;
    }

    &__meta {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        padding: .2rem .4rem;
        background-color: var(--team-theme-captain-background);
        color: var(--team-theme-captain-text);
        font-family: var(--team-theme-title-font);
        font-size: 1.2rem;
        text-transform: uppercase;
    }

    &__meta-icon {
        width: 1.6rem;
        height: 1.6rem;
        margin: 0 .2rem;
        fill: currentColor;
    }

    &__captain {
        line-height: .9;
    }

    &__info-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &--link {

        @include hover {

            #{$this}__image-container::after {
                transform: translateY(0);
            }

            #{$this}__image {
                transform: translateY(-1.6rem);
            }
        }
    }

    @media (max-width: $break-phablet) {

        &__captain {
            font-size: 1.2rem;
        }

        &__surname {
            @include truncate(75%);
            display: inline-block;
        }
    }

    @media (max-width: $break-mobile) {

        &__image-container {
            height: 14rem;
            padding-top: 0;

            &.team-player-background {
                background-image: url(../../i/team-player-backgrounds/default.jpg) !important; // stylelint-disable-line declaration-no-important
                background-position: 0 -7rem;
            }
        }

        &__image {
            width: 50%;
            left: auto;

            &--silhouette {
                right: 0;

                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &__img {
            object-position: top;
        }

        &__caption {
            bottom: $spacing-s;
            padding-left: $spacing-s;
            text-align: left;
        }

        &__surname {
            width: auto;
        }

        &__role {
            margin: 0;
        }
    }

    &.t-southern-brave {

        #{$this}__meta-icon {
            fill: var(--team-theme-primary);
        }
    }
}
