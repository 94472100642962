.reveal-component {
    transition: opacity .35s ease, transform .35s ease;

    &-appear {
        opacity: 0;
        transform: translateX(-3rem);
    }

    &-appear-done {
        opacity: 1;
        transform: translateX(0);
    }

    @media (max-width: $break-tablet) {

        &-appear {
            transform: translateY(3rem);
        }

        &-appear-done {
            transform: translateY(0);
        }
    }
}
