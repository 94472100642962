/* stylelint-disable */

.video-player {
    transition: all .2s ease-in-out;

    &__content {
        position: relative;
        padding-top: $aspect-16-9;
        width: 100% !important;
        height: 0 !important;

        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        video {
            width: 100%;
            height: auto;
        }

        &.vjs-fullscreen {
            padding-bottom: 0;
        }
    }
}

.video-js {
    overflow: hidden;

    @include hover {

        .vjs-big-play-button {
            background-color: var(--team-theme-primary);
            opacity: 1;
        }
    }

    .vjs-big-play-button {
        width: 12rem;
        height: 12rem;
        background-image: url(../../i/elements/play.svg);
        background-color: var(--team-theme-primary);
        background-size: 6.5rem;
        background-position: 3.5rem center;
        background-repeat: no-repeat;
        transform: translate(-50%, -50%);
        margin: 0;
        opacity: .6;
        transition: opacity $transition !important;

        &::before {
            display: none;
        }

        .vjs-icon-placeholder {
            display: none !important;
        }
    }

    .vjs-slider,
    .vjs-load-progress,
    .vjs-play-progress,
    .vjs-volume-level {
        height: 1rem;
        border-radius: .5rem;
    }

    .vjs-load-progress {
        overflow: hidden;
    }

    &.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-control-bar,
    &.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-control-bar:not( .vjs-focus-within ):not( .vjs-control-bar-visible ),
    &.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-control-bar:not( .vjs-focus-within ):not( .vjs-control-bar-visible ),
    &.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-control-bar:not( .vjs-focus-within ):not( .vjs-control-bar-visible ),
    &.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar:not( .vjs-focus-within ):not( .vjs-control-bar-visible ),
    &.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar:not( .vjs-focus-within ):not( .vjs-control-bar-visible ) {
        transform: translateY(5.7rem) !important;
    }

    .vjs-control-bar {
        display: flex !important;
        background-color: rgba($black, .9) !important;
        height: $control-bar-height !important;

        *,
        *::before,
        *::after {
            line-height: $control-bar-height !important;
            transform: none !important;
        }

        * {
            font-size: 1.4rem;
        }
    }

    .vjs-time-tooltip {
        line-height: 1 !important;
    }

    .vjs-mouse-display .vjs-time-tooltip {
        background-color: var(--team-theme-primary);
    }

    .vjs-mouse .vjs-progress-control {
        height: auto !important;
        top: auto !important;
    }

    .vjs-progress-control:hover {
        height: .9rem !important;
        top: auto !important;
    }

    .vjs-progress-control {
        position: relative !important;
        left: auto !important;
        right: auto !important;
        width: auto !important;
        top: auto !important;
        height: .9rem !important;
        margin: auto;
        min-width: auto !important;
        flex: 1 1 100% !important;
        margin-left: 2rem;
    }

    .vjs-dock-text {
        display: none;
    }

    .vjs-time-control {
        padding: 0 !important;
        font-weight: $bold;
    }

    .vjs-volume-control {
        display: flex;
        align-items: center;
        height: 100% !important;
    }

    .vjs-volume-bar {
        width: 6rem;
        margin: 0;
    }

    .vjs-play-progress,
    .vjs-volume-level {
        background-color: var(--team-theme-primary);
    }

    .vjs-poster {
        background-size: cover;
    }

    @media (max-width: $break-phablet) {

        .vjs-big-play-button {
            width: 8rem;
            height: 8rem;
            background-position: 2.3rem center;
            background-size: 4.5rem;
        }

        .vjs-control-bar {

            * {
                font-size: 1.2rem;
            }
        }
    }
}
