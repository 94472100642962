.latest-feed {
    $this: &;
    --card-min-height: 19rem;
    --card-loading-width: 8rem;

    &__notification {
        @include button-reset;
        display: flex;
        align-items: center;
        padding: 0 1.2rem;
        border-radius: 2rem;
        background-color: $white;
        text-transform: uppercase;
        font-size: 1.2rem;
        line-height: 2.6rem;
        font-weight: $bold;
        z-index: $level-five;
        transition: transform $transition, background-color $transition, opacity $transition;
    }

    &__notification-icon {
        width: 1rem;
        height: 1rem;
        fill: currentColor;
    }
    
    @media (min-width: $break-tablet) {
        height: calc(100vh - #{$scorebox-height});
        position: relative;
        display: flex;
        background-color: $dark-ui;
        overflow: hidden;
    
        &__card-loading {
            width: var(--card-loading-width);
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            background-color: rgba($black, .8);
        }
    
        &__card-loading-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &__notification {
            position: absolute;
            top: 2.4rem;
            box-shadow: 0 1px 2px 1px rgba($black, .2);

            &-appear {
                transform: translateY(-2rem);
                opacity: 0;
            }
    
            &-appear-done {
                transform: translateY(0);
                opacity: 1;
            }

            &:hover {
                transform: translateY(-.1rem);
                background-color: $very-light-ui;
            }

            &--front {
                left: $spacing;
                padding-right: 1.6rem;

                #{$this}__notification-icon {
                    margin-right: .6rem;
                }
            }

            &--back {
                right: $spacing;
                padding-left: 1.6rem;

                #{$this}__notification-icon {
                    margin-left: .6rem;
                }
            }
        }
        
        &__feed {
            width: 100%;
            height: 100%;
            position: relative;
        }
    
        &__item {
            width: 75%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
            user-select: none;
            background: linear-gradient(to right, $black 50%, $darkest-ui);
            transform: translateX(-101%);
            transition: transform .5s ease;
            will-change: transform;

            &.next { // the next feed item
                transform: translateX(calc(-1 * (100% - var(--card-loading-width)))) rotateZ(0);
                z-index: $level-four;

                #{$this}__card::after { // overlay
                    opacity: .4;
                }

                &:hover {
                    transform: translateX(calc(-1 * (100% - (var(--card-loading-width) + 2rem))));
                }
            }
    
            &.primary { // the main, focused feed item
                cursor: default;
                user-select: auto;
                transform: translateX(var(--card-loading-width)) rotateZ(0);
                z-index: $level-three;

                #{$this}__card::after { // overlay
                    opacity: 0;
                }
            }
        
            &.secondary { // secondary feed item
                transform: translateX(23%) rotateZ(0);
                z-index: $level-two;

                #{$this}__card::after { // overlay
                    opacity: .6;
                }
            }
        
            &.tertiary { // tertiary feed item
                transform: translateX(34%) rotateZ(0);
                z-index: $level-one;

                #{$this}__card::after { // overlay
                    opacity: .8;
                }
            }
        }

        &__card {
            width: 100%;
            height: 100%;
            position: relative;
            user-select: none;
    
            &::after { // inactive overlay
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: $level-two;
                opacity: 1;
                pointer-events: none;
                background-color: $black;
                transition: opacity .4s .1s ease;
            }
        }

        &__card-inner {
            width: 100%;
            height: 100%;
        }
    
        &__card-title {
            color: $white;
            
            &--active { // the title appears when this card is active
                position: relative;
                display: inline-block;
                margin-bottom: $spacing-l;
                font-size: 4rem;
    
                &::after { // underline
                    content: '';
                    width: 40%;
                    height: 2px;
                    position: absolute;
                    bottom: -.4rem;
                    left: 0;
                    background-color: currentColor;
                }
            }
    
            &--inactive { // the title that appears when the card is not the main card in view
                position: absolute;
                right: $spacing;
                bottom: $spacing;
                transform: rotate(180deg);
                text-shadow: 0 0 22px rgba($black, .8);
                font-size: 6rem;
                writing-mode: vertical-lr;
            }
        }
    }
    
    @media (max-width: $break-tablet) {
        background-color: $white;

        &__notification {
            position: fixed;
            right: $spacing;
            bottom: calc(#{$navigation-height-tablet} + #{$spacing});
            box-shadow: 0 3px 6px 0 rgba($black, 0.4);
            padding-left: 1.6rem;

            &.is-hidden,
            &-appear {
                transform: translateY(3rem);
                opacity: 0;
            }
    
            &-appear-done {
                transform: translateY(0);
                opacity: 1;
            }
        }

        &__notification-icon {
            margin-left: .6rem;
        }
        
        &__feed {
            padding: $spacing $spacing-s 0;
            overflow: hidden;
        }

        &__item {
            min-height: var(--card-min-height);
            position: relative;
            margin-bottom: $spacing-s;
            background: $light-placeholder-gradient;
        }

        &__card {
            width: 100%;
            position: relative;
        }

        &__card-inner {
            width: 100%;
        }
        
        &__card-title {
            position: relative;
            display: inline-block;
            margin-bottom: $spacing;
            font-size: 1.8rem;
            color: $white;

            &::after { // underline
                content: '';
                width: 40%;
                height: 2px;
                position: absolute;
                bottom: -.4rem;
                left: 0;
                background-color: currentColor;
            }
        }
    }

    @media (max-width: $break-mobile) {

        &__notification {
            font-size: 1rem;
            padding: 0 1.2rem;
            right: $spacing-s;
            box-shadow: 0 2px 4px 0 rgba($black, 0.4);
        }

        &__notification-icon {
            width: 1rem;
            height: 1rem;
        }
    }
}