.countdown {
    $this: &;
    display: flex;
    align-items: center;

    &__units-container {
        display: flex;
        width: 100%;
    }

    &__section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-width: 8.5rem;
    }

    &__value,
    &__divider {
        font-size: 7.2rem;
        font-family: var(--team-theme-title-font);
        line-height: .78;
    }

    &__label {
        font-size: 1.2rem;
        margin-top: .6rem;
        opacity: .5;
        text-transform: uppercase;
    }

    &__divider {
        margin: 0 .4rem;
    }

    @media (max-width: $break-wide) {

        &__section {
            min-width: 7rem;
        }

        &__value,
        &__divider {
            font-size: 5.8rem;
        }
    }

    @media (max-width: $break-tablet) {

        &__section {
            min-width: 5rem;
        }

        &__value,
        &__divider {
            font-size: 4.2rem;
        }

        &__label {
            font-size: 1rem;
        }
    }

    @media (max-width: $break-phablet) {
        color: $white;

        &__section {
            min-width: 4.7rem;
        }

        &__value,
        &__divider {
            font-size: 4rem;
        }

        &__label {
            font-size: .8rem;
        }
    }

    @media (max-width: $break-mobile) {

        &__section {
            min-width: 4.2rem;
        }

        &__value,
        &__divider {
            font-size: 3.5rem;
        }

        &__label {
            margin-top: .4rem;
        }
    }
}
