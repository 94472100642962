// Mixins

@mixin clearfix { // Stops element with floated children from collapsing

    &::before {
        content: '';
        display: block;
    }

    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

/*
 * When a full-width widget is inside a container, this mixin uses viewport width to break it out of that container
 * Note that overflow-x: hidden should be applied to to <body>, as the vw calculation does not take into account static scrollbar width
 */

@mixin full-width-widget {
    width: 100%;

    @supports (width: 100vw) {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;

        @media (max-width: $break-tablet) {
            width: auto;
            margin-left: -2rem;
            margin-right: -2rem;
            left: auto;
            right: auto;
        }

        @media (max-width: $break-phablet) {
            margin-left: -1rem;
            margin-right: -1rem;
        }
    }
}

@mixin link-reset { // remove default browser styling of a link tag
    color: inherit;
    text-decoration: none;
}

@mixin button-reset { //remove default browser styling of a button tag
    background: transparent;
    border: 0;
    outline: 0;
    padding: 0;
}

/*
 * Adds hover and focus styles for tablet (by default) and up
 * Only keeps focus styles for below tablet (by default)
 * If focus set to false then only hover styling will be applied
 */

@mixin hover($breakpoint: $break-tablet, $focus: true) {

    @if $focus == true {

        &:focus {
            @content;
        }
    }

    @media (min-width: $breakpoint) {

        &:hover {
            @content;
        }
    }
}

@mixin retina-bg($file, $type, $width, $height) {
    // stylelint-disable-next-line function-url-quotes
    background-image: url($file + '.' + $type);

    @media (-webkit-min-device-pixel-ratio: 2), (-moz-min-device-pixel-ratio: 2) {

        & {
            // stylelint-disable-next-line function-url-quotes
            background-image: url($file + '@x2.' + $type);
            background-size: $width $height;
        }
    }
}

@mixin retina-bg-cover($file, $type) {
    // stylelint-disable-next-line function-url-quotes
    background-image: url($file + '.' + $type);
    background-size: cover;
    background-repeat: no-repeat;

    @media (-webkit-min-device-pixel-ratio: 2), (-moz-min-device-pixel-ratio: 2) {

        & {
            // stylelint-disable-next-line function-url-quotes
            background-image: url($file + '@x2.' + $type);
        }
    }
}

/*
 * Gives a container with a fixed position child element equal dimensions
 * to prevent content from moving behind
 */

@mixin fixed-container($fixed-container-height) {
    width: 100%;
    height: $fixed-container-height;
    display: block;
    position: relative;
    top: 0;
}

/*
 * Applies an ellipsis to text that overflows the width passed into the mixin
 * NB this ONLY works on single lines of text
 */

@mixin truncate($width: 100%) {
    width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*
 * Applies an ellipsis to text that overflows the amount of lines given
 *
 * max-height and oveflow hidden workaround for non-webkit browsers
 * Please use rem values for the line-height param on this mixin as the max height calculation needs a unit
 */

@mixin truncate-multiline($line: 2, $line-height: 1.8rem) {
    max-height: $line * $line-height;
    overflow: hidden;

    // stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix

    @supports (-webkit-line-clamp: $line) {
        display: -webkit-box;
        -webkit-line-clamp: $line;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
    }

    // stylelint-enable
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
  https://github.com/bfred-it/object-fit-images
*/

@mixin object-fit($fit: fill, $position: null) {
    // stylelint-disable-next-line property-no-vendor-prefix
    -o-object-fit: $fit;
    object-fit: $fit;

    @if $position {
        // stylelint-disable-next-line property-no-vendor-prefix
        -o-object-position: $position;
        object-position: $position;
        font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    } @else {
        font-family: 'object-fit: #{$fit}';
    }
}

/**
 * applies an incrementally larger transition delays to child elements of the element this is applied to
 */

@mixin staggered-transition($items: 10, $step: .1) { // adds an increasing transition delay to a list of items to stagger their animation

    @for $i from length($items) through $items {

        &:nth-child( #{$i} ) {
            transition-delay: #{$i * $step}s;
        }
    }
}

/**
 * applies an incrementally larger animation delays to child elements of the element this is applied to
 */

@mixin staggered-animation($items: 10, $step: .1) { // adds an increasing transition delay to a list of items to stagger their animation

    @for $i from length($items) through $items {

        &:nth-child( #{$i} ) {
            animation-delay: #{$i * $step}s;
        }
    }
}
