.scorebox {
    $this: &;
    height: $scorebox-height;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    transition: transform .35s ease;

    &-appear {
        transform: translateY(-100%);

        .chase-bar {
            transform: translateY(-1.4rem);
        }
    }

    &-appear-done {
        transform: translateY(0);

        .chase-bar {
            transform: translateY(0);
        }
    }

    &__wrapper {
        height: 100%;
    }

    &__main {
        height: 100%;
        position: relative;
    }

    &__inner-container {
        height: 100%;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        
        &--pre {
            display: grid;
            padding: $spacing-s 0;
            grid-template-columns: 100% 34.6rem 100%;
        }

        &--live {
            display: flex;
            justify-content: space-around;
            padding-bottom: $spacing-s;

            #{$this}__team-container {
                flex-basis: 30%;
                justify-content: flex-start;
            }

            #{$this}__team-badge {
                margin-right: $spacing;
            }

            #{$this}__score-alt {
                font-size: 8.8rem;
                pointer-events: none;

                &--next {
                    top: -6.2rem;
                }

                &--prev {
                    bottom: -6.2rem;
                }
            }
        
            #{$this}__score {
                font-size: 8.8rem;
            }
        }

        &--post {
            display: grid;
            grid-template-columns: 100% 34.6rem 100%;
            
            #{$this}__scores {
                justify-content: space-around;
            }

            #{$this}__score-alt {
                display: none;
            }
        
            #{$this}__score {
                font-size: 6.2rem;
            }
        }
    }

    &__team-container {
        display: flex;
        justify-content: center;
        align-items: center;

        &--home {
            justify-self: flex-end;

            #{$this}__team-name {
                margin-right: $spacing;
                text-align: right;
            }
        }

        &--away {
            justify-self: flex-start;

            #{$this}__team-name {
                margin-left: $spacing;
            }
        }
    }

    &__team-badge {
        width: 7.4rem;
        height: 7.4rem;
    }

    &__team-name {
        font-size: 4rem;
        line-height: .8;
        text-transform: uppercase;
        font-family: var(--team-theme-title-font);

        .highlight {
            display: block;
        }
    }

    &__countdown {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__countdown-label {
        color: $primary;
        font-size: 1.4rem;
        font-weight: $bold;
        margin-bottom: .4rem;
        text-transform: uppercase;
    }

    // POST MATCH

    &__scores {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__score-container {
        position: relative;

        &--alt {

            #{$this} {

                &__score,
                &__score-alt {
                    font-size: 4.8rem;
                    line-height: 1;
                }

                &__score-alt {

                    &--next {
                        top: -3.5rem;
                    }

                    &--prev {
                        bottom: -3.5rem;
                    }
                }
            }
        }
    }

    &__score {
        position: relative;
        font-family: var(--team-theme-title-font);
        line-height: .64;

        &--alt {
            color: $light-ui;
        }
    }

    &__score-alt {
        width: 100%;
        position: absolute;
        font-family: var(--team-theme-title-font);
        line-height: .64;
        text-align: right;

        &--next {
            background: linear-gradient(to top, $very-light-ui, rgba($very-light-ui, 0));
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &--prev {
            background: linear-gradient(to bottom, $very-light-ui, rgba($very-light-ui, 0));
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &__score-runs {
        background-color: $primary;
        padding: .1rem .3rem;
        font-size: 1.2rem;
        line-height: 1;
        text-transform: uppercase;
        position: absolute;
        bottom: -1.6rem;
        right: 0;
    }

    &__versus {
        flex-shrink: 0;
        width: 4.4rem;
        height: 4.4rem;
        position: relative;
        text-align: center;
        color: $primary;
        font-size: 1.8rem;
        font-weight: $bold;
        border: .3rem solid $primary;
        border-radius: 100%;

        &::before,
        &::after {
            content: '';
            position: absolute;
            background: $white;
            height: 1rem;
            width: 1rem;
            transform: rotate(45deg);
        }

        &::before {
            top: 0;
            right: 0;
        }

        &::after {
            bottom: 0;
            left: 0;
        }
    }

    &__versus-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__additional-text {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        text-align: center;
        padding: $spacing-s;
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: $bold;
    }

    // LIVE MATCH

    &__runs-container,
    &__balls-container,
    &__wickets-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }

    &__runs-label {
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: $primary;
        font-size: 1.4rem;
        font-weight: $bold;
        margin-left: 1.3rem;
        text-transform: uppercase;
    }

    &__runs-label-alt {
        background-color: $primary;
        color: $white;
        font-family: var(--team-theme-title-font);
        font-size: 4rem;
        line-height: .8;
        padding: .2rem .5rem;
    }

    &__label {
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: $primary;
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: $bold;
        line-height: 1;
        margin-right: 1.2rem;
        text-align: right;
    }

    &__icon-arrows {
        width: 2.2rem;
    }

    @media (max-width: $break-wide) and (min-width: 1180px) {

        &__inner-container {
            
            &--pre {
                grid-template-columns: 100% 28rem 100%;

                #{$this}__team-badge {
                    width: 6rem;
                    height: 6rem;
                }

                #{$this}__score,
                #{$this}__score-alt {
                    font-size: 5.2rem;
                }

                #{$this}__score-alt {

                    &--next {
                        top: -4rem;
                    }

                    &--prev {
                        bottom: -4rem;
                    }
                }
            }

            &--live {

                #{$this}__score,
                #{$this}__score-alt {
                    font-size: 5.2rem;
                }

                #{$this}__score-alt {

                    &--next {
                        top: -4rem;
                    }

                    &--prev {
                        bottom: -4rem;
                    }
                }
            }

            &--post {
                grid-template-columns: 100% 28rem 100%;

                #{$this}__team-badge {
                    width: 6rem;
                    height: 6rem;
                }

                #{$this}__score {
                    font-size: 5.2rem;
                }
            }
        }

        &__icon-arrows {
            width: 1.6rem;
        }

        &__runs-label-alt {
            font-size: 2.4rem;
        }
    }

    @media (max-width: 1180px) {

        &__inner-container {
            
            &--pre {
                grid-template-columns: 100% 34.6rem 100%;
            }

            &--live {

                #{$this}__team-container {
                    flex-basis: 0;
                }

                #{$this}__team-badge {
                    margin: 0 $spacing-s;
                }
            }

            &--post {
                grid-template-columns: 100% 40.6rem 100%;

                #{$this}__team-badge {
                    width: 8rem;
                    height: 8rem;
                    margin: 0 $spacing;
                }

                #{$this}__score {
                    font-size: 8.2rem;
                }
            }
        }

        &__team-name {
            display: none;
        }
    }

    @media (max-width: $break-desktop) {

        &__inner-container {

            &--live {
                
                #{$this}__score-alt {
                    font-size: 6rem;

                    &--next {
                        top: -4.4rem;
                    }

                    &--prev {
                        bottom: -4.4rem;
                    }
                }

                #{$this}__score {
                    font-size: 6rem;
                }
            }
        }

        &__score-container--alt {

            #{$this} {

                &__score,
                &__score-alt {
                    font-size: 3.6rem;
                    margin-bottom: 0;
                }

                &__score-alt {

                    &--next {
                        top: -2.7rem;
                    }

                    &--prev {
                        bottom: -2.7rem;
                    }
                }
            }
        }

        &__runs-label-alt {
            font-size: 3rem;
        }
    }

    @media (max-width: $break-tablet) {
        height: auto;
        background-color: $black;
        color: $white;

        &__icon-in-play {
            margin-left: auto;
        }

        &__main {
            position: relative;
        }
        
        &__inner-container {
            padding: $spacing 0;
            
            &--pre {
                grid-template-columns: 100% auto 100%;
                grid-gap: $spacing;

                #{$this}__team-badge {
                    width: 7.2rem;
                    height: 7.2rem;
                    margin: 0 $spacing;
                }
            }

            &--post {
                grid-template-columns: 100% 36.6rem 100%;

                #{$this}__score {
                    font-size: 6.8rem;
                    line-height: .7;
                }
            }
        }

        &__score-container--alt {

            #{$this} {

                &__score,
                &__score-alt {
                    font-size: 4.8rem;
                }

                &__score-alt {

                    &--next {
                        top: -4.4rem;
                    }

                    &--prev {
                        bottom: -4.4rem;
                    }
                }
            }
        }

        &__team-badge {
            width: 6.4rem;
            height: 6.4rem;
        }

        &__countdown {
            margin: 0;
        }

        &__countdown-label {
            font-size: .8rem;
            background-color: $white;
            padding: .2rem .8rem;
            margin-bottom: .8rem;
        }

        &__score--alt {
            color: rgba($white, .2);
        }

        &__score-alt {

            &--next {
                background: linear-gradient(rgba($black, 0) 10%, rgba($white, .1));
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &--prev {
                background: linear-gradient(rgba($white, .1), rgba($black, 0));
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        &__versus {
            width: 3.2rem;
            height: 3.2rem;
            margin: 0 $spacing-s;
            line-height: 2.9rem; // height - border-width
            border-width: .2rem;
            font-size: 1.2rem;

            &::before,
            &::after {
                background: $black;
                height: .6rem;
                width: .6rem;
            }
        }

        &__runs-container {
            flex-direction: row-reverse;
        }

        &__score-runs {
            @include title-font;
            letter-spacing: .6px;
            bottom: -1.8rem;
        }

        &__label,
        &__runs-label {
            @include title-font;
            margin: 0 $spacing-s 0 0;
            color: $white;
            letter-spacing: .5px;
            line-height: 1;
        }

        &__runs-label {
            align-items: flex-end;
            justify-content: flex-end;
        }

        &__runs-label-alt {
            margin-right: 0;
            padding: .1rem .2rem;
            line-height: 1;
            font-size: 2rem;
        }

        &__label-alt {
            margin-left: .2rem;
        }

        &__icon-arrows {
            width: 1.8rem;
            margin: $spacing-s $spacing $spacing-s 0;

            .arrows-live__arrow {
                fill: $white;
            }
        }

        &__additional-text {
            top: auto;
            bottom: 100%;
            font-size: 1.2rem;
            white-space: nowrap;
        }
    }

    @media (max-width: $break-phablet) {

        &__inner-container {

            &--pre {
                min-height: 6.4rem;
                grid-template-columns: 1fr 1fr 1fr;

                #{$this}__team-badge {
                    width: 6.4rem;
                    height: 6.4rem;
                    margin: 0 $spacing-s;
                }
            }

            &--live {
                justify-content: space-around;
                align-items: flex-start;
            }

            &--post {
                min-height: 6.4rem;
                grid-template-columns: 1fr 1fr 1fr;

                #{$this}__team-badge {
                    width: 6.4rem;
                    height: 6.4rem;
                    margin: 0 $spacing-s;
                }

                #{$this}__score {
                    font-size: 4.4rem;
                }
            }
        }
            
        &__runs-container,
        &__balls-container,
        &__wickets-container {
            flex-grow: 0;
        }

        &__runs-container {
            flex-wrap: wrap;
        }
        
        &__wickets-text { // only shown under phablet
            width: 100%;
            margin-top: 1rem;
            text-align: center;
            color: $primary;
            font-size: 1.2rem;
            font-weight: $bold;
            text-transform: uppercase;
        }
    }

    @media (max-width: 475px) {

        &__scores {
            margin: 0 .4rem;
        }

        &__runs-container {
            align-items: flex-end;
            flex-direction: column;
        }
        
        &__balls-container,
        &__wickets-container {
            align-items: flex-end;
            flex-direction: column-reverse;
        }

        &__label,
        &__runs-label {
            flex-direction: row;
            margin-right: 0;
            margin-top: .8rem;
        }

        &__runs-label-alt {
            margin-right: .2rem;
            font-size: 1.2rem;
        }

        &__additional-text {            
            font-size: 1rem;
            padding: .2rem $spacing-s;
        }

        &__wickets-text {
            margin-top: .4rem;
            text-align: right;
        }
    }

    @media (max-width: $break-mobile) {

        &__inner-container {

            &--live {

                #{$this}__team-badge {
                    width: 6.8rem;
                    height: 6.8rem;
                    margin: 0;
                }

                #{$this}__score-alt {
                    font-size: 4.8rem;

                    &--next {
                        top: -3.6rem;
                    }

                    &--prev {
                        bottom: -3.6rem;
                    }
                }

                #{$this}__score {
                    font-size: 4.8rem;
                }
            }
        }

        &__countdown-label {
            margin-bottom: .4rem;
        }

        &__wickets-text {
            font-size: 1.1rem;
        }
    }

    @media (max-width: $break-small-mobile) {

        &__inner-container {
            padding: $spacing-s 0;

            &--pre {
                grid-gap: .4rem;

                #{$this}__team-badge {
                    width: 5.2rem;
                    height: 5.2rem;
                }
            }

            &--live {
                padding-bottom: 1rem;
            }

            &--post {

                #{$this}__team-badge {
                    width: 5.2rem;
                    height: 5.2rem;
                }

                #{$this}__score {
                    font-size: 3.4rem;
                }
            }
        }

        &__score-runs {
            font-size: 1rem;
            bottom: -1.6rem;
        }
    }
}
